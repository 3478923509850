import React, { Component } from 'react';
import { Link, RouteProps } from 'react-router-dom';

import { observer } from 'mobx-react'

import ProgressiveImage from '../components/ProgressiveImage';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import { default as Tooltip } from '@material-ui/core/Tooltip';

import {
  withStyles,
  createMuiTheme,
  ThemeProvider,
  Theme
} from '@material-ui/core/styles';

import PostAdd from '@material-ui/icons/PostAdd';
import ListAlt from '@material-ui/icons/ListAlt';
import ViewList from '@material-ui/icons/ViewList';


import { MySnackbarContentWrapper } from '../components/Snackbar';

import { UserStore } from '../stores/UserStore';
import withStore from '../stores/withStore';

import userImg from '../images/user.svg';
import navLogo from '../images/navlogo.svg';

import pencilLowRes from '../images/simple-pencil.svg';
import pencil from '../images/pencil2.svg';

import '../styles/Nav.scss';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5386b0',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
     '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const styles = (theme: Theme) => ({
  root: {
    flex: '0, 0, 10%',
  },
  title: {
    flexGrow: 1,
  },
  viewPosts: {
    marginRight: theme.spacing(1),
    color: '#565656',
  },
  addPost: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    background: 'transparent',
  },
});

interface RProps extends RouteProps {
  children: any;
  getAuthUser: any;
  userStore: UserStore;
}

@observer
class Nav extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      profileAnchorEl: null,
      postsAnchorEl: null,
    }
  }

  handlePostsMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ postsAnchorEl: event.currentTarget });
  }

  handlePostsClose = () => {
    this.setState({ postsAnchorEl: null });
  };

  handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ profileAnchorEl: event.currentTarget });
  }

  handleProfileClose = () => {
    this.setState({ profileAnchorEl: null });
  };

  logout = async () => {
    await this.props.userStore.logoutUser();
    this.props.history.push('/');
  }

  render() {
    const { classes } = this.props;
    const { children, error, userStore, errorStore } = this.props;
    const profileOpen = Boolean(this.state.profileAnchorEl);
    const postsOpen = Boolean(this.state.postsAnchorEl);
    let profileImage = userImg;
    let profileEmail = 'unknown@gmail.com';
    if (userStore.user) {
      profileImage = userStore.user.photo_url;
      profileEmail = userStore.user.email_address;
    }

    return (
      <ThemeProvider theme={theme}>
        <div className='nav-wrapper'>
          {
            Object.keys(errorStore.currentErrors).map((key: string) => {
              return (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={true}
                  onClose={() => errorStore.consumeError(key)}
                >
                  <MySnackbarContentWrapper
                    onClose={() => errorStore.consumeError(key)}
                    variant="error"
                    message={errorStore.currentErrors[key]}
                  />
                </Snackbar>
              );
            })
            }
          <div className={classes.root}>
            <AppBar
              position='static'
              className={classes.appBar}
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <div style={{
                  display: 'flex',
                }} className='unselectable'>
                  <Typography variant='h6'
                              className={classes.title}
                  >
                    <Link to='/status-page' style={{
                      textDecoration: 'none',
                      color: '#565656',
                    }}>

                    <img src={navLogo} alt='Readthisover logo' style={{ marginRight: '10px' }}/>
                      ReadThisOver
                    </Link>
                  </Typography>
                </div>
                <div className='navpencil-wrapper unselectable'>
                  <div className='navpencil unselectable'>
                    <ProgressiveImage
                      preview={pencilLowRes}
                      src={pencil}
                      render={(src: string) => <img src={src} className='configpencil-image' alt='A Pencil'/>}
                    />
                  </div>
                </div>
                <div className='navpencil-spacer'/>
                <div style={{ display: 'flex' }}>
                  <div>
                    <IconButton aria-label='View existing posts & reviews'
                                onClick={this.handlePostsMenu}
                                size='medium'
                                className={classes.viewPosts}
                    >
                      <ListAlt />
                    </IconButton>
                    <Menu
                      id='menu-appbar'
                      anchorEl={this.state.postsAnchorEl}
                      keepMounted
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      open={postsOpen}
                      onClose={this.handlePostsClose}
                    >
                      <MenuItem component={Link}
                                to='/status-page'
                                onClick={this.handlePostsClose}
                      >
                        < ViewList/>
                        <span> &nbsp; Status Page </span>
                      </MenuItem>
                      <MenuItem component={Link}
                                to='/create-a-post'
                                onClick={this.handlePostsClose}
                      >
                        < PostAdd/>
                        <span> &nbsp; Create Post </span>
                      </MenuItem>
                      {/* <MenuItem component={Link} */}
                      {/*           to='/review-a-post' */}
                      {/*           onClick={this.handlePostsClose} */}
                      {/* > */}
                      {/*   < Edit/> */}
                      {/*   <span> &nbsp; Review Post </span> */}
                      {/* </MenuItem> */}
                    </Menu>
                  </div>
                    {/* <Link to='/status-page' */}
                    {/*       style={{ */}
                    {/*         paddingTop: '1%', */}
                    {/*         paddingRight: '1%', */}
                    {/*         order: 4, */}
                    {/*         'WebkitTouchCallout': 'none', */}
                    {/*         'WebkitUserSelect': 'none', */}
                    {/*         'KhtmlUserSelect': 'none', */}
                    {/*         'MozUserSelect': 'none', */}
                    {/*         'msUserSelect': 'none', */}
                    {/*         'userSelect': 'none', */}
                    {/*       }} */}
                    {/* > */}
                    {/*   <img className='nav-image' */}
                    {/*        src={postsImg} */}
                    {/*        alt='Status page link' */}
                    {/*   /> */}
                    {/* </Link> */}
                  <div>
                    <LightTooltip title={profileEmail} placement='left'>
                      <Avatar alt='User profile picture'
                              src={profileImage}
                              className={classes.avatar}
                              aria-label='account of current user'
                              aria-controls='menu-appbar'
                              aria-haspopup='true'
                              onClick={this.handleProfileMenu}
                      />
                    </LightTooltip>
                    <Menu
                      id='menu-appbar'
                      anchorEl={this.state.profileAnchorEl}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={profileOpen}
                      onClose={this.handleProfileClose}
                    >
                      <MenuItem disabled>{profileEmail}</MenuItem>
                      <MenuItem onClick={this.logout}>Logout</MenuItem>
                    </Menu>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
          </div>
          {
            error !== undefined && (
              <div className='nav-error'>
                {/* <Alert variant="danger" onClose={() => this.props.errorClosed()} dismissible> */}
                {/*   <Alert.Heading> */}

                    { error }

                  {/* </Alert.Heading> */}
                {/* </Alert> */}
              </div>
            )
          }
          <div className='nav-wrapper-content'>
            {children}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStore(withStyles(styles)(Nav));
