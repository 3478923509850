import React, { FC } from 'react';

import '../styles/text.scss';
import '../styles/TrifoldItem.scss';

interface TrifoldItemProps {
  image: string;
  imageAlt: string;
  foldText: string;
  imageClass?: string;
}

const TrifoldItem: FC<TrifoldItemProps> = ({
  image,
  imageAlt,
  foldText,
  imageClass
}) => {
  return (
    <div className='trifolditem'>
      <img
        className={
          `unselectable ${imageClass || ''} trifolditem-image`
        }
        src={image}
        alt={imageAlt}
      />
      <div className='trifolditem-content text__dynamic'>
        <span>{foldText}</span>
      </div>
    </div>
  );
}

export default TrifoldItem;
