import React, { FC } from 'react';
import ProgressiveImage from './ProgressiveImage';
import googleLogo from '../images/google-logo.png';
import googleLogoLowRes from '../images/google-logo-low-res.png';
import spinner from '../images/spinner.svg';

import '../styles/GoogleLogoButton.scss';

import { TextButton, TextButtonProps } from './TextButton';

const ReactCSSTransitionReplace = require('react-css-transition-replace');

export interface GoogleLogoButtonProps extends TextButtonProps {
  loading?: boolean;
}

export const GoogleLogoButton: FC<GoogleLogoButtonProps> = (props) => {
  const { loading, children, ...rest } = props;
  const component: FC = ({ children }) => {
    return (
      <div className='googlelogobutton-image-wrapper'>
        {children}
      </div>
    );
  }
  const childComponent: FC = ({ children }) => {
    return (
      <div className='googlelogobutton-child-component'>
        {children}
      </div>
    );
  }

  return (
    <TextButton {...rest}>
      <ReactCSSTransitionReplace childComponent={childComponent}
                                 component={component}
                                 transitionName='cross-fade'
                                 transitionEnterTimeout={1000}
                                 transitionLeaveTimeout={1000}
      >

        {
          !loading ?
            <ProgressiveImage
              key='google'
              preview={googleLogoLowRes}
              src={googleLogo}
              render={(src: string) =>
                <img
                  src={src}
                  className='googlelogobutton-image'
                  alt='Google logo'
                />
              }
            />
          :
          <img key='loader'
               className='googlelogobutton-image'
               src={spinner}
               alt='Google logo'
          />
        }
      </ReactCSSTransitionReplace>
      {children}
    </TextButton>
  );
}
