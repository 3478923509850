import React from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps
} from 'react-router-dom';

import { useUserStore } from '../stores/useStore';
import { observer } from 'mobx-react'

import Nav from '../containers/Nav';

import { hasValidRole } from '../roles';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const PrivateRoute = observer((nProps: PrivateRouteProps) => {
  const { component: Component, ...rest } = nProps;
  const { user } = useUserStore();

  if (!user) {
    return (<Redirect to='/'/>);
  }

  if (!hasValidRole(user.roles)) {
    return (<Redirect to='/'/>);
  }

  return (
    <Route
      exact
      {...rest}
      render={(routeProps) =>
        <Nav {...routeProps}>
          <Component {...routeProps}/>
        </Nav>
      }
    />
  );
});
