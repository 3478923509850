"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ThrowReporter_1 = require("io-ts/lib/ThrowReporter");

var pipeable_1 = require("fp-ts/lib/pipeable");

var Either_1 = require("fp-ts/lib/Either");

function validateType(typeRepr, validator) {
  var potentialType = validator.decode(typeRepr);

  try {
    ThrowReporter_1.ThrowReporter.report(potentialType);
  } catch (err) {
    console.log(err);
    var paths = pipeable_1.pipe(potentialType, Either_1.fold(function (errors) {
      return errors.map(function (error) {
        return error.context.map(function (_ref) {
          var key = _ref.key;
          return key;
        }).join('.');
      });
    }, function () {
      return ['0error'];
    }));
    throw new Error("Missing fields ".concat(paths));
  }

  return typeRepr;
}

exports.validateType = validateType;