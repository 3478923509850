import React from 'react';
import { useUserStore } from '../stores/useStore';
import { observer } from 'mobx-react';

import { RouteProps } from 'react-router-dom';

import { PaddedTextButton } from '../components/PaddedTextButton';
import Nav from './Nav';

import '../styles/UserProfile.scss';

interface UserProfileProps extends RouteProps {
  history: any;
  logoutUser: any;
  getAuthUser: any;
}

const UserProfile = ({ logoutUser, history, getAuthUser }: UserProfileProps) => {
  const userStore = useUserStore();
  const handleUserLogout = async (event: any) => {
    event.preventDefault();
    await userStore.logoutUser();
    history.push('/');
  };
  return (
    <Nav getAuthUser={getAuthUser}>
      <div className='profile'>
        <PaddedTextButton buttonText='Logout'
                          onClick={handleUserLogout}
                          onKeyDown={handleUserLogout}
        />
      </div>
    </Nav>
  );
}

export default observer(UserProfile);
