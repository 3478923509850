import React, { useCallback } from 'react';
import { GoogleLogin } from 'react-google-login';

const Login: React.SFC<any> = (props: any) => {
  const onSuccess = useCallback((response: any) => {
    console.log(response);
  }, []);
  const onFailure = useCallback((response: any) => {
    console.log(response);
  }, []);
  return (
    <div className='login'>
      <GoogleLogin
        clientId='772870138929-2lgi2ems6roatmsbo47q00vpif1lgo36.apps.googleusercontent.com'
        render={renderProps => (
          <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
        )}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

export default Login;
