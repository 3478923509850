import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import { createStore, StoreProvider } from './stores/useStore';

import App from './App';

ReactDOM.render(
  <Router>
    <StoreProvider storeCreator={createStore}>
      <App />
    </StoreProvider>
  </Router>,
  document.getElementById('root')
);
