import React, { useCallback } from 'react';
import { History } from 'history';

import { observer } from 'mobx-react'

import { useUserStore, usePostStore } from '../stores/useStore';

import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from 'react-google-login';
import { request, Endpoint } from '../backend';

import { TextButton } from './TextButton';

import { hasValidRole } from '../roles';

import { GoogleLogoButton, GoogleLogoButtonProps } from './GoogleLogoButton';

interface GoogleAuthButtonProps extends GoogleLogoButtonProps {
  onFailure?: (error: any) => void;
  history: History;
  nextRoute: string;
  displayTextIfAuthenticated: boolean;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
}

export const GoogleAuthButton: React.SFC<GoogleAuthButtonProps> = observer(({
  displayTextIfAuthenticated,
  history,
  nextRoute,
  loading,
  setLoading,
  children,
  onFailure,
  ...rest
}) => {
  const userStore = useUserStore();
  const postStore = usePostStore();

  const onSuccess = useCallback(async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ((response as GoogleLoginResponseOffline).code) {
      return;
    }
    response = response as GoogleLoginResponse;
    setLoading && setLoading(true);
    const {
      profileObj,
      accessToken,
      tokenObj,
    } = response;
    const tokenPayload: any = {
      access_token: accessToken,
      ...profileObj,
      tokenObj,
    };
    tokenPayload.family_name = tokenPayload.familyName;
    tokenPayload.given_name = tokenPayload.givenName;
    tokenPayload.google_id = tokenPayload.googleId;
    tokenPayload.image_url = tokenPayload.imageUrl;
    tokenPayload.token_obj = tokenPayload.tokenObj;
    delete tokenPayload.familyName;
    delete tokenPayload.givenName;
    delete tokenPayload.googleId;
    delete tokenPayload.imageUrl;
    delete tokenPayload.tokenObj;
    try {
      const data = await request(Endpoint.LOGIN, tokenPayload);
      userStore.setUser(data);
      postStore.claimReview();
      history.push(nextRoute);
    } catch (err) {
      console.log(err);
    }
    if (setLoading) {
      setLoading(false);
    }
  }, [history, nextRoute, userStore, setLoading, postStore]);


  const GoogleLoginButton = (
    <GoogleLogin
      clientId='772870138929-2lgi2ems6roatmsbo47q00vpif1lgo36.apps.googleusercontent.com'
      render={renderProps =>
        <GoogleLogoButton {...rest} {...renderProps} loading={loading}>
          {children}
        </GoogleLogoButton>
      }
      onSuccess={onSuccess}
      onFailure={
        onFailure || ((error: any) => { return })
      }
      cookiePolicy='single_host_origin'
    />
  );

  if (userStore.user) {
    if (!hasValidRole(userStore.user.roles)) {
      return (
        <TextButton disabled={true}
                    buttonText='You are on the waitlist!'
                    {...rest}
        >
          <div style={{ minHeight: '75px' }}>
          </div>
        </TextButton>
      );
    }
    if (displayTextIfAuthenticated) {
      return (
        <TextButton {...rest}
                    onClick={() => history.push(nextRoute)}
        >
          <div style={{ minHeight: '75px' }}>
          </div>
        </TextButton>
      );
    }
  }

  return GoogleLoginButton;
})
