"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var UpdateOp;

(function (UpdateOp) {
  UpdateOp["CREATE"] = "CREATE";
  UpdateOp["UPDATE"] = "UPDATE";
  UpdateOp["REPLACE"] = "REPLACE";
  UpdateOp["REMOVE"] = "REMOVE";
})(UpdateOp = exports.UpdateOp || (exports.UpdateOp = {}));