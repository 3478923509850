import React, {
  CSSProperties,
  useEffect,
} from 'react';
import { usePostStore, useUserStore } from '../stores/useStore';
import { observer } from 'mobx-react';
import {
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';

import qs from 'qs';

import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  PostsTable,
  ReviewsTable,
} from '../components/DataTable';

import { PostPanel } from '../components/PostPanel';

import '../styles/styledtabs.scss';
import '../styles/Test.scss';

const StyledTabs = withStyles({
  indicator: {
    color: 'blue',
    backgroundColor: 'blue',
  },
})(Tabs);


const tabPaperStyles = makeStyles({
  root: {
    position: 'sticky',
    width: '100%',
    top: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // border: '1px solid rgba(0, 0, 0, 0.2)',
    alignSelf: 'stretch',
    borderRadius: 0,
    backgroundColor: '#fcfcfc',

    // flex: '0 0 auto',
  },
});


const CenteredTabs: React.SFC<any> = observer((props: any) => {
  const classes = tabPaperStyles();
  const postStore = usePostStore();

  const handleChange = (event: any, newValue: any) => {
    event.stopPropagation();
    props.setValue(newValue);
  };
  const numUserPosts = postStore.getUserPosts.length;
  const numUserReviews = postStore.getUserReviews.length;
  const numPendingReviews = postStore.getPendingReviews.length;
  return (
      <Paper className={classes.root} elevation={0} id='tabs-ele'>
        <StyledTabs
          value={props.value}
          onChange={handleChange}
          textColor='primary'
          variant='fullWidth'
        >
          <Tab label={`Your Posts (${numUserPosts})`} />
          <Tab label={`Your Reviews (${numUserReviews + numPendingReviews})`} />
        </StyledTabs>
      </Paper>
  );
});

function TabPanel(props: any) {
  const { children, value, index } = props;
  if (value !== index) {
    return null;
  }
  return children;
}

const testStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
};

interface TestProps extends RouteComponentProps {}

const Test: React.SFC<TestProps> = (props) => {
  return (
    <div style={testStyle}>
      <div className='config-page-fold test'>
        <Switch>
          <Route
            path='/post'
            component={PostPanel}
          />
          <Route
            path={['/status-page', 'test']}
            component={Table}
          />
        </Switch>
      </div>
    </div>
  );
}

interface TestProps extends RouteComponentProps {}

const Table: React.SFC<TestProps> = (props) => {
  let maybeTab = 0;
  let maybeRow = undefined;
  const queryParams = props.location && props.location.search;
  if (queryParams) {
    const qp = qs.parse(queryParams, { ignoreQueryPrefix: true });
    if (qp.tab && !isNaN(parseInt(qp.tab, 10))) {
      maybeTab = parseInt(qp.tab, 10);
      maybeTab = (maybeTab >= 2 || maybeTab < 0) ? 0 : maybeTab;
    }
    if (qp.searchRow) {
      maybeRow = qp.searchRow;
    }
  }

  const postStore = usePostStore();
  const userStore = useUserStore();

  useEffect(() => {
    const load = async () => {
      await userStore.updateAuthUser();
      await postStore.fetchAll();
    }
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [value, setValue] = React.useState(maybeTab);
  const [searchRow, setSearchRow] = React.useState(maybeRow);
  const wrappedSet = (tab: number) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('tab', `${tab}`);
    currentUrlParams.delete('row');
    props.history.push(window.location.pathname + '?' + currentUrlParams.toString());
    setValue(tab);
    setSearchRow(undefined);
  };

  const wrappedRowSet = (row?: string) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    if (row !== undefined) {
      currentUrlParams.set('searchRow', `${row}`);
    } else {
      currentUrlParams.delete('searchRow');
    }
    props.history.push(window.location.pathname + '?' + currentUrlParams.toString());
    setSearchRow(row);
  };

  return (
    <>
      <CenteredTabs setValue={wrappedSet}
                    value={value}
      />
      <TabPanel value={value} index={0}>
        <PostsTable row={searchRow} setSearchRow={wrappedRowSet}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReviewsTable row={searchRow} setSearchRow={wrappedRowSet}/>
      </TabPanel>
    </>
  );
};

export default Test;
