import { isMobile } from 'react-device-detect';
import { maybeTranslateError, ErrorCode } from 'rto-shared';

const {
  REACT_APP_BACKEND_URL,
  REACT_APP_MOBILE_URL,
} = process.env;

let remote = REACT_APP_BACKEND_URL;
if (isMobile) {
  remote = REACT_APP_MOBILE_URL;
}

export const endpoints = {
  ADVANCE_TURN: `${remote}/advance-turn`,
  ACCEPT_REVIEW: `${remote}/accept-review`,
  FINISH_REVIEW: `${remote}/finish-review`,
  REQUEST_REVIEW: `${remote}/request-review`,
  CREATE_NEW_POST: `${remote}/create-new-post`,
  REMOVE_POST: `${remote}/remove-post`,
  CLAIM_REVIEW: `${remote}/claim-review`,
  GET_SELF: `${remote}/get-self`,
  GET_POST: `${remote}/get-post`,
  LIST_POSTS: `${remote}/list-user-posts`,
  LIST_REVIEWS: `${remote}/list-user-reviews`,
  LIST_ALL: `${remote}/list-user-all`,
  LOGIN: `${remote}/login`,
  PUBLISH_POST: `${remote}/publish-post`,
  ADD_REVIEWERS: `${remote}/add-reviewers`,
  RM_REVIEWERS: `${remote}/remove-reviewers`,
  CANCEL_REVIEWS: `${remote}/cancel-reviews`,
  REVIEW_POST: `${remote}/review-post`,
  ADD_DOCS_TO_POOL: `${remote}/add-to-docs-pool`,
  AUTH_PUSHER: `${remote}/auth-pusher`,
}

export enum Endpoint {
  ADVANCE_TURN = 'ADVANCE_TURN',
  ACCEPT_REVIEW = 'ACCEPT_REVIEW',
  FINISH_REVIEW = 'FINISH_REVIEW',
  REQUEST_REVIEW = 'REQUEST_REVIEW',
  CREATE_NEW_POST = 'CREATE_NEW_POST',
  REMOVE_POST = 'REMOVE_POST',
  CLAIM_REVIEW = 'CLAIM_REVIEW',
  GET_SELF = 'GET_SELF',
  GET_POST = 'GET_POST',
  LIST_POSTS = 'LIST_POSTS',
  LIST_REVIEWS = 'LIST_REVIEWS',
  LIST_ALL = 'LIST_ALL',
  LOGIN = 'LOGIN',
  PUBLISH_POST = 'PUBLISH_POST',
  ADD_REVIEWERS = 'ADD_REVIEWERS',
  RM_REVIEWERS = 'RM_REVIEWERS',
  CANCEL_REVIEWS = 'CANCEL_REVIEWS',
  REVIEW_POST = 'REVIEW_POST',
  ADD_DOCS_TO_POOL = 'ADD_DOCS_TO_POOL',
  AUTH_PUSHER = 'AUTH_PUSHER',
}

export class BackendError extends Error {
  // TODO: Replace errorCode string with enum
  constructor(message: string, public errorCode?: ErrorCode) {
    super(message);
    this.errorCode = errorCode;
    if (this.errorCode) {
      const maybeError = maybeTranslateError(this.errorCode);
      this.message = maybeError || this.message;
    }
  }
}

export async function request(e: Endpoint, payload?: any) {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    cache: 'default',
  };
  const response = await fetch(endpoints[e], options);
  const json = await response.json();
  if (!response.ok) {
    throw new BackendError(json.message, json.code);
  }
  return json;
}

export async function authRequest(e: Endpoint, authToken: string, payload?: any) {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken,
    },
    mode: 'cors',
    cache: 'default',
  };
  const response = await fetch(endpoints[e], options);
  const json = await response.json();
  if (!response.ok) {
    if (json.errorCode) {
      throw new BackendError('', json.errorCode);
    }
    throw new Error(JSON.stringify(json));
  }
  return json;
}
