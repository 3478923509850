import React, {
  useState,
  ChangeEvent,
  MouseEvent,
  KeyboardEvent,
  FC,
} from 'react';
import { observer } from 'mobx-react';

import {
  useHistory,
  RouteComponentProps,
} from 'react-router-dom';

import { useQueryParam } from 'use-query-params';
import cls from 'classnames';

import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Delete from '@material-ui/icons/Delete';
import Back from '@material-ui/icons/ArrowBack';
import Checkbox from '@material-ui/icons/CheckBoxOutlined';
import EmptyCheck from '@material-ui/icons/CheckBoxOutlineBlank';
import Collapse from '@material-ui/core/Collapse';

import {
  ReviewablePost,
  ReviewerInviteStatus,
  Reviewer,
  BasicReview,
  PostPhase,
  ReviewStatus,
} from 'rto-shared';

import { FormTitle } from './post_creation/FormTitle';
import { checkStateToConfig } from './AddedReviewer';
import { TextButton } from './TextButton';
import {
  AddedReviewer,
  CheckConfig,
  CheckEntry,
} from './AddedReviewer';

import { validateEmail } from '../validate';

import {
  CheckOption,
  FormCheckGroup,
  Validity,
} from './FormCheckGroup';

import {
  RemoveWithTooltip,
  ChangesRequestedWithTooltip,
  CancelWithTooltip,
  SendWithTooltip,
  RequestWithTooltip,
  DoneWithTooltip,
  LaunchWithTooltip,
  GearWithTooltip,
} from './Tooltips';

import { LoaderTextButton } from './LoaderTextButton';

import { useUserStore, usePostStore } from '../stores/useStore';
import { ValidRevStatuses } from '../stores/PostStore';
import createDocLink from '../createDocLink';

import msleep from '../msleep';

import '../styles/PostPanel.scss';

interface DataItemProps {
  keyString: string;
  data: any;
}

function DataItem({ keyString, data }: DataItemProps) {
  const correctSpace = '    ';
  return (
    <div className='postpanel-item'>
      <span className='text__config'>
        {keyString}:{correctSpace}
      </span>
      <span className='text__info'>
        {data}
      </span>
    </div>
  );
}

interface ReviewerTileProps {
  reviewer: Reviewer;
  isOwner?: boolean;
  postId: string;
  isMe?: boolean;
  scrollRow?: () => void;
}

const reviewedState = [
  {
    id: 'approved',
    display: 'Approve',
    checked: false,
    exclusive: true,
  },
  {
    id: 'changes_requested',
    display: 'Request changes',
    checked: false,
    exclusive: true,
  },
];

// const checkStateFromFinishRev = (rev?: ReviewStatus): CheckOption[] => {
//   return [
//   ];
// };

const checkStateFromRev = (rev: BasicReview): CheckOption[] => {
  const validKeys = Object.keys(rev).filter((revKey) =>
    (rev as any)[revKey] && typeKeys.includes(revKey));
  const checkOptions: CheckOption[] = [{
    id: 'no_type',
    display: 'Generic review',
    checked: rev.no_type,
    exclusive: true,
  }];
  typeKeys.forEach((key) => {
    checkOptions.push({
      id: key,
      display: (typeMap as any)[key].display,
      checked: validKeys.includes(key),
    });
  });
  return checkOptions;
};

const CollapseComponent: React.FC<any> = (props: any) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}

const ReviewerTile: React.SFC<ReviewerTileProps> = observer(({
  reviewer: rev,
  isOwner,
  postId,
  isMe,
  scrollRow,
}) => {
  const currReview = rev.reviews.slice(-1)[0];
  const [isOpen, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkState, setCheckState] = useState<CheckOption[]>(checkStateFromRev(currReview));
  const [menuAnchorEl, setMenuAnchorEl] = useState<any>();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [cState, setCState] =
    useState<CheckOption[]>([...JSON.parse(JSON.stringify(reviewedState))]);
  const checkStatus = cState.filter(({ checked }) => checked);
  const isChecked = checkStatus.length === 1;

  const checkToRS = (id: string) => {
    if (id === 'approved') {
      return ReviewStatus.APPROVED;
    }
    return ReviewStatus.CHANGES_REQUESTED;
  };

  const postStore = usePostStore();
  let maybeRequest = null;
  let statusIcon = <RemoveWithTooltip />;
  // TODO FIX Me:
  let requestAvailable = false;
  const isApproved = currReview.status === ReviewStatus.APPROVED;
  const areChangesRequested = currReview.status === ReviewStatus.CHANGES_REQUESTED;
  const isCancelled = currReview.status === ReviewStatus.CANCELLED;
  if (rev.invite_status === ReviewerInviteStatus.PENDING) {
    statusIcon = <SendWithTooltip />;
    if (isCancelled) {
      requestAvailable = true;
    }
  } else if (rev.invite_status !== ReviewerInviteStatus.REJECTED && isCancelled) {
    requestAvailable = true;
    statusIcon = <CancelWithTooltip />;
  } else if (rev.invite_status === ReviewerInviteStatus.ACCEPTED &&
             isApproved) {
    requestAvailable = true;
    statusIcon = <DoneWithTooltip />;
  } else if (rev.invite_status === ReviewerInviteStatus.ACCEPTED &&
             areChangesRequested) {
    requestAvailable = true;
    statusIcon = <ChangesRequestedWithTooltip />;
  }

  if (isOwner && requestAvailable) {
    const handleRevRequest = async () => {
      const { user_id } = rev;
      if (user_id) {
        const data = {
          uid: user_id,
          reviewType: checkStateToConfig(checkState),
        };
        setLoading(true);
        await postStore.requestReview(postId, [data]);
        setLoading(false);
      }
    }
    const handleOpenType = async () => {
      if (!isOpen) {
        msleep(100).then(() => {
          scrollRow?.();
        });
      }
      setOpen(!isOpen);
    }
    maybeRequest = (
      <div>
        <RequestWithTooltip onClick={handleOpenType}/>
        {
          isOpen && (
            <Collapse
              in={isOpen}
              component={CollapseComponent}
              unmountOnExit
            >
              <div className='request-review-wrapper'>
                <div className='request-review-collapse'>
                  <FormCheckGroup
                    checkState={checkState}
                    validity={Validity.DEFAULT}
                    setCheckState={(cS: CheckOption[]) => {
                      setCheckState(cS);
                    }}
                    colorMap={{}}
                    disabled={false}
                    validated={false}
                  />
                </div>
                <div className='request-review-submit'>
                  <LoaderTextButton
                    loading={loading}
                    buttonText='Request new review'
                    onClick={handleRevRequest}
                    onKeyDown={handleRevRequest}
                  />
                </div>
              </div>
            </Collapse>
          )
        }
      </div>
    );
  }


  const handleMenuOpen = (evt: MouseEvent) => {
    setMenuAnchorEl(evt.target);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleRmReviewer = async () => {
    setLoading(true);
    setMenuAnchorEl(null);
    setMenuOpen(false);
    try {
      await postStore.rmReviewers(postId, [rev.invited_email]);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const handleCancelReview = async () => {
    setLoading(true);
    try {
      await postStore.cancelReviews(postId, [rev.invited_email]);
      setMenuAnchorEl(null);
      setMenuOpen(false);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const clearReview = () => {
    setCState([...JSON.parse(JSON.stringify(reviewedState))]);
  };

  const baseName = rev.display_name || rev.invited_email;
  const displayName = isMe ? `${baseName} (me)` : baseName;

  return (
    <div>
      <div className='postpanel-reviewer text__config'>
        {
          rev.photo_url &&
          <Tooltip
            title={rev.invited_email}
            placement='right-start'
          >
            <img
              key={rev.invited_email}
              alt='Avatar'
              className='reviewer-avatar-display'
              style={{
                width: '42px',
              }}
              src={rev.photo_url || ''}
            />
          </Tooltip>
        }
        &nbsp;&nbsp;
        {displayName}
        {isOwner && <GearWithTooltip onClick={handleMenuOpen}/>}
        <Menu
          id='reviewer-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {
            currReview.status === ReviewStatus.PENDING && (
              <MenuItem onClick={handleCancelReview}>
                Cancel review
              </MenuItem>
            )
          }
          <MenuItem onClick={handleRmReviewer}>
            Remove reviewer
          </MenuItem>
        </Menu>

        {statusIcon}
      </div>
      <ReviewTypeDisplay rev={currReview} finished={currReview.status !== 'PENDING'}/>
      {
        isMe && !requestAvailable && (
          <div className='postpanel-reviewer-selection'>
            <FormCheckGroup
              forceSingleCol={true}
              uncheckableRadios={true}
              checkState={cState}
              validity={Validity.DEFAULT}
              setCheckState={(cS: CheckOption[]) => {
                setCState(cS);
              }}
              colorMap={{}}
              disabled={false}
              validated={false}
            />
            {
              isChecked && (
                <FinishButton
                  onFinished={clearReview}
                  postId={postId}
                  status={checkToRS(checkStatus[0].id)}
                />
              )
            }
          </div>
        )
      }
      {maybeRequest}
    </div>
  );
});

interface FinishButtonProps {
  postId: string;
  status: ValidRevStatuses;
  onFinished?: () => void;
}

const FinishButton: React.FC<FinishButtonProps> = observer(({
  postId,
  status,
  onFinished,
}) => {
  const [loading, setLoading] = useState(false);
  const postStore = usePostStore();
  const userStore = useUserStore();

  const post = postStore.posts[postId];
  const user = userStore.user;
  if (!post || !user) {
    return null;
  }

  const display = status === ReviewStatus.APPROVED ? 'Approve' : 'Request changes';

  const userHasReview = user.review_ids.includes(postId);

  if (!userHasReview) {
    return  null;
  }
  const rev: Reviewer = postStore.posts[postId].reviewers.filter((rev) =>
    rev.user_id === user.uid)[0];

  const currReview = rev.reviews.slice(-1)[0];
  const isApproved = rev.reviews && currReview.status === 'APPROVED';
  if (isApproved) {
    return null;
  }

  const finishReview = async (
    evt: MouseEvent | KeyboardEvent,
    id: string
  ) => {
    evt.preventDefault();
    setLoading(true);
    try {
      await postStore.finishReview(id, status);
    } catch (err) {}
    setLoading(false);
    onFinished?.();
  }

  return (
    <div className='loader-button-wrap'>
      <LoaderTextButton
         loading={loading}
         buttonText={display}
         onClick={(evt: MouseEvent) => finishReview(evt, postId)}
         onKeyDown={(evt: KeyboardEvent) => finishReview(evt, postId)}
       />
    </div>
  );
});

interface ReviewTypeDisplayProps {
  rev: BasicReview;
  finished?: boolean;
  showAll?: boolean;
}

const typeMap = {
  technical_check: {
    display: 'Technical check',
  },
  grammar_check: {
    display: 'Grammar check',
  },
  spell_check: {
    display: 'Spell check',
  },
  high_level_check: {
    display: 'High level check',
  },
  fact_check: {
    display: 'Fact check',
  },
};

const typeKeys = Object.keys(typeMap);

export const ReviewTypeDisplay: FC<ReviewTypeDisplayProps> = observer(({
  rev,
  finished,
  showAll,
}) => {
  if (rev.no_type) {
    return null;
  }

  if (showAll) {
    const vKeys = Object.keys(rev).filter((revKey) =>
      typeKeys.includes(revKey));
    return (
      <div className='review-type-display'>
        {
          vKeys.map((key) => (
              <div className='review-type-display-item' key={key}>
                <div className='review-type-display-item-title text__info'>
                  {(typeMap as any)[key].display}
                </div>
                <div className='review-type-display-item-spacer'/>
                <div className='review-type-display-item-value'>
                  {
                    ((rev as any)[key] && typeKeys.includes(key)) ?
                      <Checkbox/>
                    :
                      <EmptyCheck/>
                  }
                </div>
              </div>
          ))
        }
      </div>
    );
  }
  const validKeys = Object.keys(rev).filter((revKey) =>
    (rev as any)[revKey] && typeKeys.includes(revKey));

  return (
    <div className='review-type-display'>
      {
        validKeys.map((key) => (
          <div className='review-type-display-item' key={key}>
            <div className='review-type-display-item-title text__info'>
              {(typeMap as any)[key].display}
            </div>
            <div className='review-type-display-item-spacer'/>
            <div className='review-type-display-item-value'>
              {
                finished ?
                  <Checkbox/>
                :
                  <EmptyCheck/>
              }
            </div>
          </div>
        ))
      }
    </div>
  );
});

interface PostPanelPreviewProps {
  postId: string;
  isOwner?: boolean;
  scrollRow: () => void;
}


const DocLink: FC<{ post: ReviewablePost }> = ({ post }) => {
  return (
    <a href={createDocLink(post.google_docs_id)}>
      {post.title}
    </a>
  );
};



interface ReviewerTilesProps {
  userId: string,
  reviewers: Reviewer[];
  postId: string;
  isOwner?: boolean;
  scrollRow: () => void;
}

export const ReviewerTiles: FC<ReviewerTilesProps> = observer(({
  userId,
  reviewers,
  postId,
  isOwner,
  scrollRow,
}) => {
  const inOrder = reviewers.sort((a, b) => {
    const aIsMe = a.user_id === userId;
    const bIsMe = b.user_id === userId;
    if (aIsMe) {
      return 1;
    } else if (bIsMe) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      {
        inOrder.map((rev) => {
          const isMe = userId === rev.user_id;
          const classes = cls(
            'postpanel-reviewstatus',
            'has-reviewers',
            { 'user-is-reviewer': isMe },
          );
          return (
            <div
              key={rev.invited_email}
              className={classes}
            >
              <ReviewerTile
                isMe={isMe}
                reviewer={rev}
                isOwner={isOwner}
                postId={postId}
                scrollRow={scrollRow}
              />
            </div>
          )
        })
      }
    </>
  )
});

export const PostPanelReviewerView: FC<PostPanelPreviewProps> = observer(({
  postId,
  scrollRow,
}) => {
  const postStore = usePostStore();
  const userStore = useUserStore();

  const post = postStore.posts[postId];
  const user = userStore.user;
  if (!post || !user) {
    return null;
  }

  const reviewers = post.reviewers.filter(({ invite_status }) =>
    invite_status !== ReviewerInviteStatus.REJECTED);

  const hasReviewers = reviewers.length > 0;
  return (
    <div className='postpanel postpanel-preview'>
      <div className='postpanel-content'>
        <DataItem keyString='Title' data={<DocLink post={post}/>} />
        { hasReviewers && <DataItem keyString='Reviewers' data=''/> }
        <ReviewerTiles
          userId={user.uid}
          isOwner={false}
          postId={postId}
          reviewers={reviewers}
          scrollRow={scrollRow}
        />
        <div className='postpanel-reviewer-button'>
          {/* <FinishButton postId={postId}/> */}
        </div>
      </div>
   </div>
  );
});

export interface PostPanelPendingProps {
  postId: string;
  reviewer: Reviewer;
}

export const PostPanelPendingView: FC<PostPanelPendingProps> = observer(({
  postId,
  reviewer,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const postStore = usePostStore();

  const post = postStore.posts[postId];
  const {
    owner_email,
    reviews,
  } = reviewer;

  const acceptReview = async (evt: MouseEvent | KeyboardEvent, id: string) => {
    evt.preventDefault();
    setLoading(true);
    await postStore.acceptReview(id);
    setLoading(false);
  }

  const currReview = reviews.slice(-1)[0];

  return (
    <div className='postpanel postpanel-preview'>
      <div className='postpanel-content'>
        <DataItem keyString='Title' data={<DocLink post={post}/>} />
        <DataItem keyString='Invited by' data={owner_email} />
        <DataItem keyString='Type' data=''/>
        <div className='invite-review-type'>
          <ReviewTypeDisplay rev={currReview} showAll={true}/>
        </div>
        <div className='postpanel-reviewer-button'>
          <LoaderTextButton
            loading={loading}
            buttonText='Accept review'
            onClick={(evt) => acceptReview(evt, postId)}
            onKeyDown={(evt) => acceptReview(evt, postId)}
          />
        </div>
      </div>
   </div>
  );
});


export const PostPanelPreview: FC<PostPanelPreviewProps> = observer(({
  postId,
  isOwner,
  scrollRow,
}) => {
  const postStore = usePostStore();
  const userStore = useUserStore();

  const post = postStore.posts[postId];
  const user = userStore.user;
  if (!post || !user) {
    return null;
  }

  const reviewers = post.reviewers.filter(({ invite_status }) =>
    invite_status !== ReviewerInviteStatus.REJECTED);


  const hasReviewers = reviewers.length > 0;
  const published = post.phase === PostPhase.PUBLISHED ? 'true' : 'false';
  return (
    <div className='postpanel postpanel-preview'>
      <div className='postpanel-content'>
        <DataItem keyString='Title' data={<DocLink post={post}/>} />
        <DataItem keyString='Published' data={published} />
        { hasReviewers && <DataItem keyString='Reviewers' data=''/> }
        <ReviewerTiles
          userId={user.uid}
          isOwner={isOwner}
          postId={postId}
          reviewers={reviewers}
          scrollRow={scrollRow}
        />
      </div>
      <div className='postpanel-far-right-col'>
        <LaunchWithTooltip destination={`/post?id=${postId}`}/>
      </div>
   </div>
  );
});

export const PostPanel: FC<RouteComponentProps> = observer(() => {
  const [addReviewerText, setAddReviewerText] = useState<string>();
  const [addedReviewers, setAddedReviewers] = useState<CheckEntry[]>([]);
  const [isAddValid, setAddValid] = useState<boolean>(false);
  const [addError, setAddError] = useState<boolean>(false);
  const [addHelper, setAddHelper] = useState<string | undefined>(undefined);
  const postStore = usePostStore();
  const userStore = useUserStore();
  const history = useHistory();

  const [postId] = useQueryParam<string>('id');
  if (!postId) {
    return null;
  }

  const post = postStore.posts[postId];
  const user = userStore.user;
  if (!post || !user) {
    return null;
  }

  const isOwner = post.owner_id === user.uid;

  const handlePostDeleted = async (evt: MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    await postStore.removePost(postId);
    history.push(`/status-page?tab=${tab}`);
  }

  const reviewers = post.reviewers.filter(({ invite_status }) =>
    invite_status !== ReviewerInviteStatus.REJECTED);

  const hasReviewers = reviewers.length;
  const tab = isOwner ? 0 : 1;


  const handleReturn = (evt: MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    history.push(`/status-page?tab=${tab}&searchRow=${postId}`);
  };
  //        <Button
  //          color='primary'
  //          component={RouterLink}
  //          to={`/status-page?tab=${tab}&searchRow=${postId}`}
  //        >
  //          Back to status page
  //        </Button>
  //
  const addReviewer = () => {
    if (!addReviewerText) {
      setAddHelper('Please add a valid email address');
      setAddError(true);
      setAddValid(true);
      return;
    }
    const validEmail = validateEmail(addReviewerText, user.email_address);

    if (!validEmail) {
      setAddHelper('Please add a valid email address');
      setAddError(true);
      setAddValid(true);
      return;
    }

    const existing = post.reviewers.map(({ invited_email }) =>
      invited_email);
    const addedEmails = addedReviewers.map(({ email }: CheckEntry) => email);

    const allReviewers = [...addedEmails, ...existing];
    const someReviewer = allReviewers.some((email: string) =>
          email === addReviewerText);
    if (someReviewer) {
      setAddHelper('Reviewer already exists with that email');
      setAddError(true);
      setAddValid(true);
      return;
    }
    setAddedReviewers([
      ...addedReviewers,
      {
        email: addReviewerText,
        reviewType: {
          no_type: true,
          spell_check: false,
          grammar_check: false,
          fact_check: false,
          high_level_check: false,
          technical_check: false,
        },
      }
    ]);
    setAddReviewerText('');
    setAddHelper(undefined);
    setAddError(false);
    setAddValid(false);
  };

  const handleAddReviewer = (evt: MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    addReviewer();
  };

  const handleSetReviewState = (email: string, rState: CheckConfig) => {
    const copy = [...addedReviewers];
    copy.forEach((reviewer: CheckEntry) => {
      if (reviewer.email === email) {
        reviewer.reviewType = rState;
      }
    });
    setAddedReviewers(copy);
  }


  const handleReviewerRemoved = (removeEmail?: string) => {
    const newEmails = addedReviewers.filter(
      ({ email }: CheckEntry) => removeEmail !== email);
    setAddedReviewers(newEmails);
  }

  const onReviewerKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      event.preventDefault();
      addReviewer();
    }
  }

  const handleSubmitReviewers = async (evt: MouseEvent | KeyboardEvent) => {
    evt.preventDefault();
    await postStore.addReviewers(
      postId,
      addedReviewers,
    );
    setAddedReviewers([]);
  };

  return (
    <div className='postpanel-wrapper'>
      <div className='postpanel-nav'>
        <div className='postpanel-return'>
          <TextButton buttonText='Status page'
                      onClick={handleReturn}
                      onKeyDown={handleReturn}
                      disabled={false}
                      buttonClass='nav-button'
                      textStyle={{
                        fontSize: '12px',
                      }}
          >
            <div style={{
              paddingLeft: '4%',
              display: 'flex',
            }} className='text__dynamic'>
              <Back fontSize='small' />
            </div>
          </TextButton>

        </div>
        <div className='postpanel-nav-spacer'/>
        {
          isOwner && (
            <div className='postpanel-delete'>
              <TextButton buttonText='Delete this post'
                          onClick={handlePostDeleted}
                          onKeyDown={handlePostDeleted}
                          disabled={false}
                          buttonClass='nav-button'
                          textStyle={{
                            fontSize: '12px',
                          }}
                          childrenRight={true}
              >
                <div style={{
                  paddingRight: '3%',
                  display: 'flex',
                }} className='text__dynamic'>
                  <Delete fontSize='small' />
                </div>
              </TextButton>
            </div>
          )
        }
      </div>
      <div className='postpanel postpanel-padded'>
          <DataItem keyString='Title' data={<DocLink post={post}/>} />
          <DataItem keyString='Stage' data={post.phase} />
          { hasReviewers > 0 && <DataItem keyString='Reviewers' data=''/> }
          {
            (hasReviewers > 0) && (reviewers.map((rev) =>
                <div
                  key={rev.invited_email}
                  className={
                    `postpanel-reviewstatus ${hasReviewers ? 'has-reviewers' : ''}`
                  }
                >
                  <ReviewerTile
                    reviewer={rev}
                    isOwner={isOwner}
                    postId={postId}
                  />
                </div>
              )
            )
          }
          {
            isOwner && (
              <div className='postpanel-add-reviewer'>
                <div className='postpanel-add-reviewer-control'>
                  <div className='postpanel-add-reviewer-input'>
                    <FormTitle disabled={false}
                               validated={isAddValid}
                               required={true}
                               label='Reviewer email'
                               onChange={(e: ChangeEvent) => {
                                 const target = e.target as HTMLTextAreaElement;
                                 setAddReviewerText(target.value);
                               }}
                               value={addReviewerText}
                               placeHolder='tom@myspace.com'
                               onKeyPress={onReviewerKeyPress}
                               helperText={addHelper}
                               error={addError}
                               size='small'
                               style={{
                                 margin: 0,
                                 height: '80%',
                                 maxHeight: '100%',
                                 display: 'flex',
                                 flexDirection: 'row',
                                 paddingTop: '0',
                                 backgroundColor: 'white',
                                 borderRadius: '7px',
                               }}
                    />
                  </div>
                  <div className='postpanel-add-reviewer-button'>
                    <TextButton buttonText='Add a Reviewer'
                                onClick={handleAddReviewer}
                                onKeyDown={handleAddReviewer}
                                disabled={false}
                                style={{
                                  minWidth: '125px',
                                  borderRadius: '3px',
                                  borderBottom: '0',
                                  height: '100%',
                                  minHeight: '35px',
                                }}
                                textStyle={{
                                  fontSize: '12px',
                                }}
                    />
                  </div>
                </div>
              </div>
            )
          }
          {
            isOwner && addedReviewers.length > 0 && (
              <div className='postpanel-added-reviewers'>
                {
                  addedReviewers.map(({ email }) => (
                    <div className='postpanel-added-reviewer'>
                      <AddedReviewer
                        key={email}
                        email={email}
                        onRemove={handleReviewerRemoved}
                        setReviewState={handleSetReviewState}
                      />
                    </div>
                  ))
                }
                <div className='submit-added-reviewers-container'>
                  <div className='submit-added-reviewers-button'>
                    <TextButton buttonText='Submit new reviewers'
                                onClick={handleSubmitReviewers}
                                onKeyDown={handleSubmitReviewers}
                                disabled={false}
                                style={{
                                  minWidth: '250px',
                                  borderRadius: '3px',
                                  borderBottom: '0',
                                  height: '100%',
                                  minHeight: '40px',
                                }}
                                textStyle={{
                                  fontSize: '16px',
                                }}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
    </div>
  );
});
