"use strict";

var _defineProperty = require("/home/ubuntu/readthisover/ts_frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _t$keyof;

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

exports.BasicReviewV = t.type({
  no_type: t.boolean,
  spell_check: t.boolean,
  grammar_check: t.boolean,
  fact_check: t.boolean,
  high_level_check: t.boolean,
  technical_check: t.boolean
});
var ReviewStatus;

(function (ReviewStatus) {
  ReviewStatus["PENDING"] = "PENDING";
  ReviewStatus["CHANGES_REQUESTED"] = "CHANGES_REQUESTED";
  ReviewStatus["CANCELLED"] = "CANCELLED";
  ReviewStatus["APPROVED"] = "APPROVED";
})(ReviewStatus = exports.ReviewStatus || (exports.ReviewStatus = {}));

exports.ReviewStatusV = t.keyof((_t$keyof = {}, _defineProperty(_t$keyof, ReviewStatus.PENDING, null), _defineProperty(_t$keyof, ReviewStatus.CHANGES_REQUESTED, null), _defineProperty(_t$keyof, ReviewStatus.CANCELLED, null), _defineProperty(_t$keyof, ReviewStatus.APPROVED, null), _t$keyof));
exports.ReviewV = t.intersection([exports.BasicReviewV, t.type({
  id: t.string,
  time_created: t.string,
  status: exports.ReviewStatusV
})]);