import React, { ReactNode } from 'react';
import { useLocalStore, useObserver } from 'mobx-react';

import { UserStore, UserStoreInterface } from './UserStore';
import { PostStore, PostStoreInterface } from './PostStore';
import { ErrorStore, ErrorStoreInterface } from './ErrorStore';

export interface AppStores {
  errorStore: ErrorStoreInterface,
  userStore: UserStoreInterface;
  postStore: PostStoreInterface;
}

export function createStore(): AppStores {
  const errorStore = new ErrorStore();
  const userStore = new UserStore(errorStore);
  const postStore = new PostStore(userStore, errorStore);

  return {
    errorStore,
    userStore,
    postStore,
  };
}

export const StoreContext = React.createContext<AppStores | null>(null);

export const StoreProvider = (props: { children?: ReactNode, storeCreator: () => AppStores }) => {
  const store = useLocalStore(props.storeCreator);
  return (
    <StoreContext.Provider value={store}>
        {props.children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(StoreContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

export const useUserStore = () => {
  const { userStore } = useStore();
  return useObserver(() => (userStore));
}

export const usePostStore = () => {
  const { postStore } = useStore();
  return useObserver(() => (postStore));
}

export const useErrorStore = () => {
  const { errorStore } = useStore();
  return useObserver(() => (errorStore));
}
