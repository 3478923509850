import { observable, action, remove, set } from 'mobx'
import { v4 as uuidv4 } from 'uuid';

// export interface ErrorEntry {
//   message: string;
//   id: string;
// }

export interface ErrorStoreInterface {
  currentErrors: { [key: string]: string };
  consumeError: (id: string) => void;
  addError: (message: string) => void;
}

export class ErrorStore implements ErrorStoreInterface {
  @observable public currentErrors = {};

  @action
  consumeError(id: string) {
    remove(this.currentErrors, id);
  }

  @action
  addError(message: string) {
    set(this.currentErrors, uuidv4(), message);
  }
}
