"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function restrictShape(source, empty) {
  var copy = Object.assign({}, empty);

  for (var prop in copy) {
    if (source[prop] && copy.hasOwnProperty(prop)) {
      copy[prop] = source[prop];
    }
  }

  return copy;
}

exports.restrictShape = restrictShape;