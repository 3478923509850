import React, { useState, FC } from 'react';
import {
  RouteProps,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import qs from 'qs';

import { GoogleAuthButton } from '../components/GoogleAuthButton';

import { useUserStore, usePostStore } from '../stores/useStore';
import { observer } from 'mobx-react'

import { hasValidRole } from '../roles';


export const ExternalRoute: FC<RouteProps> = observer(({
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const { postid } = useParams();
  const postId = postid;
  const queryParams = location && location.search;
  const userStore = useUserStore();
  const postStore = usePostStore();

  if (!postId || !queryParams) {
    // handle bad invite link
    return null;
  }

  const qp = qs.parse(queryParams, { ignoreQueryPrefix: true });

  if (!postStore.postRef ||
    !(postStore.postRef.id === postId
    || postStore.postRef.token === qp.token)) {
    postStore.setRef(postId as string, qp.token);
  }

  const toLink = `/status-page?tab=1&searchRow=${postId}`;
  const loginFlow = (
    <div className='centered-wrap'>
      <div>
        <GoogleAuthButton
          buttonText='Login to review this post!'
          displayTextIfAuthenticated={false}
          nextRoute={toLink}
          history={useHistory()}
          disabled={loading}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );

  if (!userStore.user) {
    return loginFlow;
  }
  const validUser = hasValidRole(userStore.user.roles);

  if (!validUser) {
    return <Redirect to='/'/>;
  }

  // TODO: what happens if this fails?
  postStore.claimReview();

  return <Redirect to={toLink}/>;
})
