import React, {
  FC,
  MouseEvent,
  KeyboardEvent,
  cloneElement,
  Children,
} from 'react';
import { RouteProps } from 'react-router-dom';

import cls from 'classnames';

import '../styles/GenericButton.scss';

export interface GenericButtonProps extends RouteProps {
  disabled?: boolean;
  buttonClass?: string;
  children?: any;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;
  rest?: any;
}

export const GenericButton: FC<GenericButtonProps> = ({
  onClick,
  onKeyDown,
  buttonClass,
  disabled,
  children,
  ...rest
}) => {
  let style = {
    pointerEvents: disabled ? 'none' : 'auto',
  };
  if (rest && (rest as any).style) {
    style = {
      ...style,
      ...(rest as any).style,
    };
  }
  const classes = cls('generic-button', {
    'generic-button-disabled': disabled,
    [`${buttonClass}`]: buttonClass,
  });
  const cloned = Children.map(children, (child) => {
    if (child === null) {
      return null;
    }
    return cloneElement(child);
  });
  return (
    <div className={classes}
         onClick={onClick}
         onKeyDown={onKeyDown}
         role='button'
         aria-pressed='false'
         style={style as any}
    >
      { cloned }
    </div>
  );
}
