import React, { MouseEvent } from 'react';

import { withStyles, Theme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import { default as Tooltip, TooltipProps } from '@material-ui/core/Tooltip';

export const ResizableIconButton = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down(600)]: {
      marginLeft: '8px',
    },
    marginLeft: '15px',
  },
}))(IconButton);

export const CenteredTooltip: React.FC<TooltipProps> = ({
  children,
  ...rest
}) => {
  return (
    <Tooltip {...rest}>
      <ResizableIconButton
        size='small'
        color="inherit"
        disabled={false}
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
        }}
      >
        {children}
      </ResizableIconButton>
    </Tooltip>
  );
}
