import {
  toJS,
  observable,
  action,
  computed,
  autorun,
  get,
  remove,
  set,
} from 'mobx'
import localStorage from 'mobx-localstorage';

import deepEqual from 'deep-equal';

import semver from 'semver';

import {
  BasicPost,
  BasicReview,
  PostPhase,
  ReviewOptions,
  ReviewablePost,
  ReviewablePostV,
  ReviewerInviteStatus,
  Reviewer,
  ReviewStatus,
  UpdateOp,
  validateType,
  ErrorCode,
} from 'rto-shared';

import { CheckEntry } from '../components/AddedReviewer';

import { UserStore } from './UserStore';
import { ErrorStore } from './ErrorStore';

import { authRequest, Endpoint } from '../backend';

const minVersion = '0.3.3';

const POST_KEY = 'stored_posts';

export type ValidRevStatuses = ReviewStatus.APPROVED | ReviewStatus.CHANGES_REQUESTED;

export interface Posts {
  [key: string]: ReviewablePost;
}

export interface PostRef {
  id: string;
  token: string;
}

interface ReRequestedReviewer {
  uid: string;
  reviewType: BasicReview;
}

export enum Relation {
  OWNER = 'OWNER',
  REVIEWER = 'REVIEWER',
  NONE = 'NONE',
}

export interface PostRelation {
  relation: Relation;
  pending?: boolean;
}

export interface PostStoreInterface {
  posts: Posts;
  postRef: PostRef | undefined;
  setRef: (id: string, token: string) => void;
  clearRef: () => void;
  claimReview: () => void;
  acceptReview: (postId: string) => Promise<void>;
  finishReview: (postId: string, status: ValidRevStatuses) => Promise<void>;
  requestReview: (postId: string, reviewers: ReRequestedReviewer[]) => Promise<void>;
  getUserPosts: ReviewablePost[];
  getUserReviews: ReviewablePost[];
  getPendingReviews: ReviewablePost[];
  getPostById: (postId: string) => Promise<ReviewablePost>;
  getPostRelation: (id: string) => PostRelation;
  fetchAll: () => Promise<ReviewablePost[]>;
  fetchUserPosts: () => Promise<ReviewablePost[]>;
  fetchUserReviews: () => Promise<ReviewablePost[]>;
  createPost: (post: BasicPost) => Promise<ReviewablePost>;
  removePost: (postId: string) => Promise<void>;
  publishPost: (postId: string, reviewers: CheckEntry[]) => Promise<ReviewablePost>;
  addReviewers: (postId: string, reviewers: CheckEntry[]) => Promise<ReviewablePost>;
  rmReviewers: (postId: string, reviewers: string[]) => Promise<ReviewablePost>;
  cancelReviews: (postId: string, reviewers: string[]) => Promise<ReviewablePost>;
  advancePost: (postId: string) => Promise<ReviewablePost>;
  reviewPost: (reviewOptions: ReviewOptions) => Promise<ReviewablePost>;
  postUpdated: (updatedPost: any) => void;
}

export class PostStore implements PostStoreInterface {
  @observable public posts: Posts = {};
  @observable public postRef: PostRef | undefined;
  private userStore: UserStore;
  private errorStore: ErrorStore;

  constructor(userStore: UserStore, errorStore: ErrorStore) {
    this.userStore = userStore;
    this.errorStore = errorStore;
    const maybeVersion: unknown = localStorage.getItem('posts_version');
    if (maybeVersion) {
      const storedVersion = maybeVersion as string;
      if (semver.lt(storedVersion, minVersion)) {
        localStorage.removeItem(POST_KEY);
      }
    } else {
      localStorage.removeItem(POST_KEY);
    }

    const maybePosts: unknown = localStorage.getItem(POST_KEY);
    if (maybePosts !== null) {
      const unvalidated = maybePosts as any;
      try {
        Object.keys(unvalidated).forEach((maybeKey: any) => {
            const maybePost = unvalidated[maybeKey];
            set(this.posts, maybeKey,
            // this.posts[maybeKey] =
              validateType<ReviewablePost>(maybePost, ReviewablePostV));
        });
      } catch (err) {
        localStorage.removeItem(POST_KEY);
        this.posts = {};
      }

      localStorage.setItem('posts_version', process.env.REACT_APP_VERSION);
    }
    autorun(() => {
      if (this.userStore.userChannel) {
        this.userStore.userChannel.bind('post-update', this.postUpdated);
      }
    });
    autorun(async () => {
      if (this.userStore.loggedIn) {
        await this.tryReq(this.fetchAll());
      } else {
        this.posts = {};
        localStorage.removeItem(POST_KEY);
      }
    });
  }

  private throwAndStore(err: any): never {
    this.errorStore.addError(err.message);
    throw err;
  }

  private async tryReq(req: Promise<any>): Promise<any> {
    try {
      const result = await req;
      return result;
    } catch (err) {
      if (err.errorCode) {
        if (err.errorCode === ErrorCode.E_NO_SUCH_USER ||
            err.errorCode === ErrorCode.E_INVALID_CREDENTIALS) {
          console.log('logging out user');
          await this.userStore.logoutUser();
        }
      } else if (this.userStore.user) {
        this.throwAndStore(err);
      }
    }
  }

  @action
  public setRef(id: string, token: string): void {
    if (this.postRef) {
      this.postRef.token = token;
      this.postRef.id = id;
    } else {
      this.postRef = {
        id,
        token,
      }
    }
  }

  public clearRef(): void {
    this.postRef = undefined;
  }

  @computed
  get getPendingReviews(): ReviewablePost[] {
    const currUser = this.userStore.user;
    if (currUser !== undefined) {
      const pendingReviews = Object.keys(this.posts).filter((postId: string) => {
        const currPost = this.posts[postId];
        const reviewer = currPost.reviewers.some(({ user_id, invite_status }) =>
          user_id === currUser.uid && invite_status === ReviewerInviteStatus.PENDING);
        const notOwner = currPost.owner_id !== currUser.uid;
        return reviewer && notOwner && currPost.phase !== PostPhase.REMOVED;
      });

      return pendingReviews.map((postId) =>
        get(this.posts, postId));
    }
    return [];
  }

  @computed
  get getUserPosts(): ReviewablePost[] {
    if (this.userStore.user && this.userStore.user.post_ids.length) {
      const existingPosts = this.userStore.user.post_ids.filter((postId) => {
        return get(this.posts, postId) && this.posts[postId].phase !== PostPhase.REMOVED;
      });

      return existingPosts.map((postId) =>
        get(this.posts, postId));
    }
    return [];
  }

  @computed
  get getUserReviews(): ReviewablePost[] {
    if (this.userStore.user !== undefined && this.userStore.user.review_ids.length) {
      const existingReviews = this.userStore.user.review_ids.filter((reviewId) => {
        const rev = get(this.posts, reviewId);
        if (rev && rev.phase !== PostPhase.REMOVED) {
          return rev.reviewers.some((reviewer: Reviewer) =>
            reviewer.user_id === this.userStore.user!.uid
              && reviewer.invite_status !== ReviewerInviteStatus.PENDING);
        }
        return false;
      });

      return existingReviews.map((reviewId) =>
        get(this.posts, reviewId));
    }
    return [];
  }

  @action
  async getPostById(postId: string): Promise<ReviewablePost> {
    if (this.posts && get(this.posts, postId)) {
      return get(this.posts, postId);
    } else {
      try {
        const loadedPost = await this.fetchPost(postId);
        set(this.posts, postId, loadedPost);
        localStorage.setItem(POST_KEY, this.posts);
        return loadedPost;
      } catch (err) {
        console.error(err);
        return this.throwAndStore(new Error(`Failed to fetch post with Id: ${postId}`));
      }
    }
  }

  private async fetchPost(postId: string): Promise<ReviewablePost> {
    if (this.userStore.user) {
      const maybePosts = await this.tryReq(authRequest(Endpoint.GET_POST,
      this.userStore.user.token as string, { postId }));
      const realPost = maybePosts.filter((post: any) => post.id !== postId);
      return validateType<ReviewablePost>
                      (realPost[0], ReviewablePostV);
    }
    return this.throwAndStore(new Error('Cannot retrieve post without valid user'));
  }

  private comparePosts(post0: ReviewablePost, post1: ReviewablePost): boolean {
    return Object.keys(post0).some((postKey) => {
      if ((post0 as any)[postKey] !== (post1 as any)[postKey]) {
        return false;
      }
      return true;
    });
  }

  @action
  private async fetchPosts(e: Endpoint, overwrite?: boolean): Promise<ReviewablePost[]> {
    if (this.userStore.user) {
      const maybePosts = await this.tryReq(authRequest(e,
        this.userStore.user.token as string, undefined));
      if (!(maybePosts?.length >= 0)) {
        return [];
      }
      try {
        const validPosts = maybePosts.map((maybePost: any) => {
          const validPost = validateType<ReviewablePost>(maybePost, ReviewablePostV);
          return validPost;
        });
        validPosts.forEach((validPost: ReviewablePost) => {
          // TODO(Ry): check if stored post is more recent
          //           once we have timestamps/hashes
          const equal = deepEqual(
            toJS(get(this.posts, validPost.id)), validPost);
          if (!equal) {
            set(this.posts, validPost.id, validPost);
          }
        });

        if (overwrite) {
          const postKeys = Object.keys(this.posts);
          postKeys.forEach((key) => {
            const isValid = validPosts.some(({ id }: ReviewablePost) =>
              id === key);
            if (!isValid) {
              remove(this.posts, key);
            }
          });
        }
        // TODO (Ry): (only execute if some post was not eq)
        localStorage.setItem(POST_KEY, this.posts);
        localStorage.setItem('posts_version', process.env.REACT_APP_VERSION);
        return validPosts;
      } catch (err) {
        return this.throwAndStore(err);
      }
    }
    return this.throwAndStore(new Error('User undefined, cannot fetch posts'));
  }

  @action
  async fetchAll(): Promise<ReviewablePost[]> {
    return this.fetchPosts(Endpoint.LIST_ALL, true);
  }

  @action
  async fetchUserPosts(): Promise<ReviewablePost[]> {
    return this.fetchPosts(Endpoint.LIST_POSTS);
  }

  @action
  async fetchUserReviews(): Promise<ReviewablePost[]> {
    return this.fetchPosts(Endpoint.LIST_REVIEWS);
  }

  @action
  async createPost(post: BasicPost, copyUrl?: string): Promise<ReviewablePost> {
    if (this.userStore.user) {
      const createdPost = await this.tryReq(authRequest(
        Endpoint.CREATE_NEW_POST,
        this.userStore.user.token as string, { post, copyUrl }));
      set(this.posts, createdPost.id, createdPost);
      localStorage.setItem(POST_KEY, this.posts);
      return createdPost;
    }
    return this.throwAndStore(new Error('Cannot create post for undefined user'));
  }

  @action
  async removePost(postId: string): Promise<void> {
    if (this.userStore.user) {
      const userToken = this.userStore.user.token as string;
      await this.tryReq(authRequest(Endpoint.REMOVE_POST,
        userToken, { postId }));
      delete this.posts[postId];
    }
  }

  private async updatePost(payload: any, e: Endpoint): Promise<ReviewablePost> {
    if (this.userStore.user) {
      const updatedPost = await this.tryReq(authRequest(e,
        this.userStore.user.token as string, payload));
      set(this.posts, updatedPost.id, updatedPost);
      localStorage.setItem(POST_KEY, this.posts);
      return updatedPost;
    }
    return this.throwAndStore(new Error(`Cannot update post for undefined user`));
  }


  claimReview = async (): Promise<void> => {
    if (this.userStore.user && this.postRef) {
      const userToken = this.userStore.user.token as string;
      await this.tryReq(authRequest(Endpoint.CLAIM_REVIEW,
        userToken, this.postRef));
      this.clearRef();
    }
  }

  @action
  async acceptReview(postId: string): Promise<void> {
    if (this.userStore.user) {
      const user = this.userStore.user;
      const userToken = user.token as string;
      await this.tryReq(authRequest(Endpoint.ACCEPT_REVIEW,
        userToken, { postId }));
      this.posts[postId].reviewers.forEach((reviewer: Reviewer) => {
        if (reviewer.user_id === user.uid) {
          reviewer.invite_status = ReviewerInviteStatus.ACCEPTED;
        }
      });
      this.userStore.addReviewToUser(postId);
    }
  }

  @action
  async finishReview(
    postId: string,
    status: ValidRevStatuses,
  ): Promise<void> {
    if (get(this.userStore, 'user') && this.userStore.user) {
      const user = this.userStore.user;
      const userToken = user.token as string;
      await this.tryReq(authRequest(Endpoint.FINISH_REVIEW,
        userToken, { postId, status }));
      this.posts[postId].reviewers.forEach((reviewer: Reviewer) => {
        if (reviewer.user_id === user.uid) {
          reviewer.reviews[0].status = status;
        }
      });
    }
  }

  @action
  async requestReview(postId: string, reviewers: ReRequestedReviewer[]): Promise<void> {
    if (this.userStore.user) {
      const user = this.userStore.user;
      const userToken = user.token as string;
      const reqRevs = await this.tryReq(authRequest(Endpoint.REQUEST_REVIEW, userToken, { postId, reviewers, }));

      this.posts[postId].reviewers.forEach((rev: Reviewer) => {
        const match = reqRevs.filter(({ id }: any) => id === rev.id);
        if (match.length) {
          rev = match[0];
        }
      });
    }
  }

  @action
  async publishPost(postId: string, reviewers: CheckEntry[]): Promise<ReviewablePost> {
    return await this.updatePost({
      postId,
      reviewers,
    }, Endpoint.PUBLISH_POST);
  }

  @action
  async addReviewers(postId: string, reviewers: CheckEntry[]): Promise<ReviewablePost> {
    return await this.updatePost({
      postId,
      reviewers,
    }, Endpoint.ADD_REVIEWERS);
  }

  @action
  async rmReviewers(postId: string, reviewers: string[]): Promise<ReviewablePost> {
    return await this.updatePost({
      postId,
      reviewers,
    }, Endpoint.RM_REVIEWERS);
  }

  @action
  async cancelReviews(postId: string, reviewers: string[]): Promise<ReviewablePost> {
    return await this.updatePost({
      postId,
      reviewers,
    }, Endpoint.CANCEL_REVIEWS);
  }

  @action
  async advancePost(postId: string): Promise<ReviewablePost> {
    return await this.updatePost({ postId },
      Endpoint.ADVANCE_TURN);
  }

  @action
  async reviewPost(reviewOptions: ReviewOptions): Promise<ReviewablePost> {
    return await this.updatePost({ reviewOptions },
      Endpoint.REVIEW_POST);
  }

  getPostRelation(id: string): PostRelation {
    return computed(() => {
      const post = this.posts[id];
      const nonRelation = { relation: Relation.NONE };
      if (!post || !this.userStore.user) {
        return nonRelation;
      }

      const user = this.userStore.user;
      if (post.owner_id === user.uid) {
        return {
          relation: Relation.OWNER,
        }
      }

      const maybeReviewer = post.reviewers.filter((rev: Reviewer) => {
        return user && rev.user_id === user.uid;
      });

      if (!maybeReviewer.length) {
        return nonRelation;
      }

      return {
        relation: Relation.REVIEWER,
        pending: maybeReviewer[0].invite_status === ReviewerInviteStatus.PENDING,
      }
    }).get();
  }

  @action
  postUpdated = async (update: any) => {
    const { id, data, op } = update;
    if (op === UpdateOp.UPDATE) {
      if (data && data.reviewer_ids) {
        const user: any = this.userStore.user;
        const { reviewers } = data;
        const amReviewer = reviewers.some(({ user_id }: Reviewer) =>
          user_id === user.uid);
        if (amReviewer && !user.review_ids.includes(id)) {
          user.review_ids.push(id);
        }
      }
      const merge = {
        ...this.posts[id] || {},
        ...data,
      }
      try {
        const valid = validateType<ReviewablePost>(merge, ReviewablePostV);
        set(this.posts, id, valid);
      } catch (err) {
        console.error('Post update included invalid post');
      }
    } else if (op === UpdateOp.CREATE) {
      try {
        const valid = validateType<ReviewablePost>(data, ReviewablePostV);
        set(this.posts, id, valid);
        localStorage.setItem(POST_KEY, this.posts);
      } catch (err) {
        console.error('Post update included invalid post');
      }
    } else if (op === UpdateOp.REMOVE) {
      remove(this.posts, id);
      localStorage.setItem(POST_KEY, this.posts);
    }
  }
}

  // @action
  // async fetchUserReviews(): Promise<ReviewablePost[]> {
  //   if (this.userStore.user) {
  //     const maybeReviews = await authRequest(Endpoint.LIST_REVIEWS,
  //       this.userStore.user.token as string, undefined);
  //     const validReviews = maybeReviews.map((maybeReview: any) => {
  //       const validReview = validateType<ReviewablePost>(maybeReview, ReviewablePostV);
  //       return validReview;
  //     });

  //     validReviews.forEach((validReview: ReviewablePost) => {
  //       // TODO(Ry): check if stored post is more recent
  //       //           once we have timestamps/hashes
  //       this.posts[validReview.id] = validReview;
  //     });
  //     localStorage.setItem(POST_KEY, this.posts);
  //     return validReviews;
  //   }
  //   throw new Error(`User undefined, cannot fetch reviews`);
  // }
