import React, { FC } from 'react';

import '../styles/LoaderTextButton.scss';

import Sharpener from './Sharpener';
import { TextButton, TextButtonProps } from './TextButton';

export interface LoaderTextButtonProps extends TextButtonProps {
  loading?: boolean;
}

export const LoaderTextButton: FC<LoaderTextButtonProps> = (props) => {
  const { loading, ...rest } = props;

  const style = (props && props.style) ? props.style : {};
  style.minHeight = '75px';

  const bText = loading ? undefined : props.buttonText;

  return (
    <TextButton
      {...rest}
      style={style}
      buttonText={bText}
    >
        {
          loading && (
            <div className='loadertextbutton-image-wrapper'>
              <div className='sharpener-wrapper'>
                <Sharpener />
              </div>
            </div>
          )
        }
    </TextButton>
  );
}
