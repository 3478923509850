import React from 'react';

import { TextButton, TextButtonProps } from './TextButton';

export const PaddedTextButton: React.SFC<TextButtonProps> = (props: TextButtonProps) => {
  const style = (props && props.style) ? props.style : {};
  style.minHeight = '75px';
  return (
    <TextButton  style={style} {...props}>
      {props.children}
    </TextButton>
  );
}
