"use strict";

var _regeneratorRuntime = require("/home/ubuntu/readthisover/ts_frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/regenerator");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

var ThrowReporter_1 = require("io-ts/lib/ThrowReporter");

var pipeable_1 = require("fp-ts/lib/pipeable");

var Either_1 = require("fp-ts/lib/Either");

var optional_1 = require("../optional");

var ReviewTag_1 = require("./ReviewTag");

var ReviewType_1 = require("./ReviewType");

exports.ReviewOptionsV = optional_1.fixOptionals(t.type({
  review_types: ReviewType_1.ReviewTypeV,
  tags: optional_1.optional(t.array(ReviewTag_1.ReviewTagV)),
  allow_partial_matches: t.boolean
})); // TODO: Genericize this

function validateReviewOptions(reviewRepr) {
  var potentialReviewOpts, paths;
  return _regeneratorRuntime.async(function validateReviewOptions$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          potentialReviewOpts = exports.ReviewOptionsV.decode(reviewRepr);
          _context.prev = 1;
          ThrowReporter_1.ThrowReporter.report(potentialReviewOpts);
          _context.next = 9;
          break;

        case 5:
          _context.prev = 5;
          _context.t0 = _context["catch"](1);
          paths = pipeable_1.pipe(potentialReviewOpts, Either_1.fold(function (errors) {
            return errors.map(function (error) {
              return error.context.map(function (_ref) {
                var key = _ref.key;
                return key;
              }).join('.');
            });
          }, function () {
            return ['0error'];
          }));
          throw new Error("Missing fields ".concat(paths));

        case 9:
          return _context.abrupt("return", reviewRepr);

        case 10:
        case "end":
          return _context.stop();
      }
    }
  }, null, null, [[1, 5]]);
}

exports.validateReviewOptions = validateReviewOptions;