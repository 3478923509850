import React from 'react';

import FormControl from '@material-ui/core/FormControl';

import {
  ColorMap,
  FormCheckGroup,
  Validity,
  CheckOption
} from '../FormCheckGroup';

import { makeStyles } from '@material-ui/core/styles';

import '../../styles/PostForm.scss';

interface FormCheckProps {
  checkState: CheckOption[],
  validity: Validity,
  disabled: boolean,
  validated: boolean,
  setCheckState?: (cS: CheckOption[], cV: Validity) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const colorMap: ColorMap = {
  [Validity.DEFAULT]: 'rgba(0, 0, 0, 0.1)',
  [Validity.VALID]: '#28a745',
  [Validity.INVALID]: '#dc3545',
};

// TODO (Ry): Style the label
export const FormCheck: React.SFC<FormCheckProps> = ({
  checkState,
  validity,
  disabled,
  validated,
  setCheckState
}: FormCheckProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root} fullWidth={true}>
      <label className='text__info' style={{
        marginBottom: '10px',
        marginTop: '10px',
      }}>
        Review Type (multiple allowed, 1 required)
      </label>
      <FormCheckGroup checkState={checkState}
                      validity={validity}
                      setCheckState={setCheckState}
                      colorMap={colorMap}
                      disabled={disabled}
                      validated={validated}
      />
    </FormControl>
  );
}
