import { UserRole } from 'rto-shared';

export const validRoles = [UserRole.BETA, UserRole.ADMIN];

export const hasValidRole = (roles: UserRole[] | undefined) => {
  if (!roles) {
    return false;
  }
  return validRoles.some((role) => roles.includes(role));
};
