import React, {
  FC,
  MouseEvent,
  useState
} from 'react';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import {
  Validity,
  ColorMap,
  CheckOption,
  FormCheckGroup,
} from './FormCheckGroup';

import '../styles/AddedReviewer.scss';

export interface CheckEntry {
  email: string;
  reviewType: CheckConfig;
}

const CollapseComponent: React.FC<any> = (props: any) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}

const colorMap: ColorMap = {
  [Validity.DEFAULT]: 'rgba(0, 0, 0, 0.0)',
  [Validity.VALID]: '#28a745',
  [Validity.INVALID]: '#dc3545',
};
  // {
  //   id: 'notype',
  //   display: 'Generic review',
  //   checked: true,
  // },

const initialCheckState = [
  {
    id: 'no_type',
    display: 'Generic review',
    checked: true,
    exclusive: true,
  },
  {
    id: 'spell_check',
    display: 'Spell Check',
    checked: false,
  },
  {
    id: 'grammar_check',
    display: 'Grammar Check',
    checked: false,
  },
  {
    id: 'fact_check',
    display: 'Fact Check',
    checked: false,
  },
  {
    id: 'high_level_check',
    display: 'High Level Check',
    checked: false,
  },
  {
    id: 'technical_check',
    display: 'Technical Check',
    checked: false,
  },
];

export const checkStateToConfig = (state: CheckOption[]): CheckConfig => {
  let config: any = {};
  state.forEach(({ id, checked }) => {
    config[id] = checked;
  });
  return config as CheckConfig;
}

export interface CheckConfig {
  no_type: boolean;
  spell_check: boolean;
  grammar_check: boolean;
  fact_check: boolean;
  high_level_check: boolean;
  technical_check: boolean;
}

interface AddedReviewerProps {
  email: string;
  onRemove: (email?: string) => void;
  setReviewState: (email: string, rState: CheckConfig) => void;
}

export const AddedReviewer: FC<AddedReviewerProps> = ({
  email,
  onRemove,
  setReviewState,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [checkState, setCheckState] = useState<CheckOption[]>(JSON.parse(JSON.stringify(initialCheckState)));
  const [checkValid, setCheckValid] = useState<Validity>(Validity.DEFAULT);
  const wrappedRemove = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    onRemove(email);
  }
  const handleOpen = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setOpen(!isOpen);
  }

  return (
    <div className='added-reviewer'>
      <div
        className='added-reviewer-wrapper'
        onClick={handleOpen}
      >
        <div className='added-reviewer-email text__header'> {email}
        </div>
        <div className='added-reviewer-spacer-4'/>
        <div className='added-reviewer-expand'>
          <label className='text__info added-reviewer-hidden-responsive'>
            Customize review
          </label>
          <ExpandMore />
        </div>
        <button
          className='added-reviewer-remove'
          onClick={wrappedRemove}
        >
            <HighlightOffIcon fontSize='large'/>
        </button>
      </div>
      {
        <Collapse
          in={isOpen}
          component={CollapseComponent}
          unmountOnExit
        >
          <div className='added-reviewer-collapse'>
            <FormCheckGroup
              checkState={checkState}
              validity={checkValid}
              setCheckState={(cS: CheckOption[], cV: Validity) => {
                setCheckValid(cV);
                setCheckState(cS);
                setReviewState(email, checkStateToConfig(checkState));
              }}
              colorMap={colorMap}
              disabled={false}
              validated={false}
            />
          </div>
        </Collapse>
      }
      { isOpen && <div className='added-reviewer-spacerpx-20'/> }
    </div>
  );
};
