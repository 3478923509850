"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));
/**
 * Fix signature by marking all fields with undefined as optional
 */


exports.fixOptionals = function (c) {
  return c;
};
/**
 * Just an alias for T | undefined coded
 */


exports.optional = function (c) {
  return t.union([t.undefined, c]);
};

exports.Nullable = t.union([t.null, t.undefined]);