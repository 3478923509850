import React, { MouseEvent, FC } from 'react';
import { RouteProps } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

import '../styles/FormCheckGroup.scss';

export const ZeroMarginFCLabel = withStyles({
  root: {
    margin: 0,
  },
})((props: FormControlLabelProps) => <FormControlLabel {...props} />);

export enum Validity {
  DEFAULT = 0,
  VALID = 1,
  INVALID = 2,
}

export interface CheckOption {
  id: string;
  display: string;
  checked: boolean;
  exclusive?: boolean;
}

export interface ColorMap {
  [key: string]: string;
}

function hasCheckedOption(potCheck: CheckOption[]) {
  return potCheck.some(({ checked }) => {
    return checked !== false;
  });
};

function makeFormGroupFromChecks(
  checks: CheckOption[],
  disabled: boolean,
  handleCheckbox: (event: MouseEvent) => void,
) {

  return (
    <FormGroup key={checks[0].id}>
      {
        checks.map((check: any) => (
          <ZeroMarginFCLabel
            id={check.id}
            key={check.id}
            control={
              !check.exclusive ?
                <Checkbox id={check.id}
                          checked={check.checked}
                          onClick={handleCheckbox}
                          value={check.display}
                          disabled={disabled}
                          color='primary'
                />
              :
                <Radio   id={check.id}
                         checked={check.checked}
                         onClick={handleCheckbox}
                         value={check.display}
                         disabled={disabled}
                         color='primary'
                />

            }
            label={check.display}
          />
        ))
      }
    </FormGroup>
  );
}

export interface FormCheckGroup extends RouteProps {
  setCheckState?: (cS: CheckOption[], cV: Validity) => void;
  checkState: CheckOption[];
  validity: Validity;
  colorMap: ColorMap;
  disabled: boolean;
  validated: boolean;
  forceSingleCol?: boolean;
  uncheckableRadios?: boolean;
}

type InnerChecksProps = Pick<FormCheckGroup,
  'disabled' | 'checkState' | 'forceSingleCol'>
  & { handleCheckbox: (evt: MouseEvent) => void; };

const InnerChecks: FC<InnerChecksProps> = ({
  disabled,
  checkState,
  handleCheckbox,
  forceSingleCol,
}) => {
  const matches = useMediaQuery('(max-width:400px)');

  if (!matches && !forceSingleCol) {
    const firstHalfSize = Math.ceil((checkState.length / 2));
    const firstHalfSlice = checkState.slice(0, firstHalfSize);
    const secondHalfSlice = checkState.slice(firstHalfSize, checkState.length);
    return (
      <div className='split-check-group'>
        {makeFormGroupFromChecks(firstHalfSlice, disabled, handleCheckbox)}
        {makeFormGroupFromChecks(secondHalfSlice, disabled, handleCheckbox)}
      </div>
    );
  }
  return makeFormGroupFromChecks(checkState, disabled, handleCheckbox);
}

export const FormCheckGroup: FC<FormCheckGroup> = ({
  checkState,
  setCheckState,
  validity,
  colorMap,
  disabled,
  validated,
  forceSingleCol,
  uncheckableRadios,
}) => {
  const handleCheckbox = (event: MouseEvent) => {
    const targetId = (event.target as HTMLInputElement).id;
    const [getCheck] = checkState.filter(({ id }) => targetId === id);
    const { exclusive } = getCheck;
    console.log(getCheck);
    const updatedChecks = checkState.map((c) => {
      // if the radio has the same Id as our choice, it means
      // the user is currently selecting it
      if (exclusive) {
        if (uncheckableRadios) {
          c.checked = (c.id === getCheck.id) && !(c.checked && uncheckableRadios);
        } else {
          c.checked = c.id === getCheck.id;
        }
      } else if (c.exclusive) {
        c.checked = false;
      } else {
        c.checked = (targetId === c.id) ? !c.checked : c.checked;
      }
      return c;
    });
    const hasCheck = hasCheckedOption(updatedChecks);
    let newValidity = hasCheck ? Validity.VALID : Validity.INVALID;
    if (setCheckState !== undefined) {
      setCheckState(updatedChecks, newValidity);
    }
  }

  let borderColor = colorMap[Validity.DEFAULT];
  if (validated) {
    borderColor = colorMap[validity];
  }

  return (
    <div
      className='form-check-group unselectable'
      style={{ borderColor }}
    >
      <InnerChecks
        checkState={checkState}
        handleCheckbox={handleCheckbox}
        disabled={disabled}
        forceSingleCol={forceSingleCol}
      />
    </div>
  );
}
