import React, { CSSProperties } from 'react';

import { GenericButton, GenericButtonProps } from './GenericButton';

import '../styles/TextButton.scss';

export interface TextButtonProps extends GenericButtonProps {
  buttonText?: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  childrenRight?: boolean;
}

export function TextButton({
  buttonText,
  textStyle,
  children,
  disabled,
  childrenRight,
  ...rest
}: TextButtonProps) {
  const disabledClass = disabled ? 'text__disabled' : '';
  return (
    <GenericButton {...rest} disabled={disabled}>
      {!childrenRight && children}
      {
        buttonText && (
          <div
            className={
              `textbutton-text text__dynamic ${disabledClass}`
          }>
            <span
              style={textStyle}
              className={`text__dynamic ${disabledClass}`}
            >
              {buttonText}
            </span>
          </div>
        )
      }
      {childrenRight && children}
    </GenericButton>
  );
}
