import { toJS, observable, action } from 'mobx'
import localStorage from 'mobx-localstorage';
import Pusher from 'pusher-js';

import deepEqual from 'deep-equal';

import {
  ErrorCode,
  ClientUser,
  ClientUserV,
  UpdateOp,
  validateType
} from 'rto-shared';

import { isDefined } from '../isDefined';
import { authRequest, endpoints, Endpoint } from '../backend';

import { ErrorStore } from './ErrorStore';

import semver from 'semver';

const minVersion = '0.3.1';
const { REACT_APP_VERSION } = process.env;

export interface UserStoreInterface {
  user: ClientUser | undefined;
  userChannel: any;
  loggedIn: boolean;
  setUser: (user: ClientUser) => void;
  updateAuthUser: () => void;
  logoutUser: () => void;
  addReviewToUser: (reviewId: string) => void;
}

export class UserStore implements UserStoreInterface {
  @observable public user: ClientUser | undefined;
  private pusherClient: Pusher.Pusher | undefined;
  @observable public userChannel: any;
  @observable public loggedIn: boolean = false;
  private errorStore: ErrorStore;

  constructor(errorStore: ErrorStore) {
    this.errorStore = errorStore;
    const maybeVersion: unknown = localStorage.getItem('user_version');
    if (maybeVersion) {
      const storedVersion = maybeVersion as string;
      if (semver.lt(storedVersion, minVersion)) {
        console.log('stored version is less than min version');
        localStorage.removeItem('user_profile');
      }
    } else {
      localStorage.removeItem('user_profile');
    }
    try {
      // now retrieve the token
      const maybeUser: unknown = localStorage.getItem('user_profile');
      if (maybeUser !== null) {
        const storedUser = maybeUser as ClientUser;
        const user = validateType<ClientUser>(storedUser, ClientUserV);
        localStorage.setItem('user_version', REACT_APP_VERSION);
        this.user = user;
        this.loggedIn = true;
        this.connectPusher();
        this.updateAuthUser().catch(() => {});
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem('user_profile');
      // TODO: Handle errors nicely
    }
  }

  private throwAndStore(err: any): never {
    this.errorStore.addError(err.message);
    throw err;
  }

  private async tryReq(req: Promise<any>): Promise<any> {
    try {
      const result = await req;
      return result;
    } catch (err) {
      if (err.errorCode) {
        if (err.errorCode === ErrorCode.E_NO_SUCH_USER ||
            err.errorCode === ErrorCode.E_INVALID_CREDENTIALS) {
          console.log('logging out user');
          await this.logoutUser();
        }
      } else {
        this.throwAndStore(err);
      }
    }
  }

  private isUserDefined(): this is { user: ClientUser }  {
    if (!this.user) {
      throw new Error('Attempted action with undefined user');
    }
    return isDefined(this.user);
  }

  @action
  public setUser = (user: ClientUser) => {
    if (deepEqual(toJS(this.user), user)) {
      return;
    }
    if (!this.loggedIn) {
      this.loggedIn = true;
    }
    localStorage.setItem('user_profile', user);
    localStorage.setItem('user_version', REACT_APP_VERSION);
    this.user = user;
    this.connectPusher();
  }

  @action
  public updateAuthUser = async () => {
    if (this.isUserDefined()) {
      const userToken = this.user.token as string;
      const newUser = await this.tryReq(authRequest(Endpoint.GET_SELF,
        userToken, {}));
      this.setUser({ ...this.user, ...newUser });
    }
  }

  @action
  logoutUser = async () => {
    localStorage.removeItem('user_profile');
    this.user = undefined;
    this.loggedIn = false;
    if (this.pusherClient !== undefined) {
      this.pusherClient.disconnect();
    }
    this.pusherClient = undefined;
  }

  @action
  addReviewToUser = (reviewId: string): void => {
    if (this.isUserDefined()) {
      const hasReview = this.user.review_ids.filter((id) =>
        id === reviewId);
      if (!hasReview.length) {
        this.user.review_ids = [...this.user.review_ids, reviewId];
      }
      // TODO: Save back to localStorage
    }
  }

  private userUpdated = async (update: any) => {
    const { op } = update;
    if (op === UpdateOp.UPDATE) {
      this.user = {
        ...this.user,
        ...update.data,
      };
    } else if (op === UpdateOp.REPLACE) {
      try {
        this.user = validateType<ClientUser>(update.data, ClientUserV);
      } catch (err) {
        console.error('Bad user update received from server');
      }
    }
  }

  private async connectPusher(): Promise<void> {
    if (this.isUserDefined()) {
      this.pusherClient = new Pusher('f342b7448f12802800d1', {
        cluster: 'us3',
        forceTLS: false, // TODO: Fix this for prod
        authEndpoint: endpoints.AUTH_PUSHER,
        auth: {
          headers: {
            'Authorization': this.user.token,
          },
        },
        disableStats: true,
      });
      const channelId = `private-userChannel-${this.user.uid}`;
      this.userChannel = this.pusherClient.subscribe(channelId);
      this.userChannel.bind('user-update', this.userUpdated);
    }
  }
}
