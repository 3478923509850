import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import ProgressiveImage from '../components/ProgressiveImage';

import '../styles/Privacy.scss';

import logo from '../images/finallogo.svg';
import logoLowRes from '../images/lowreslogo.png';

export const Privacy: FC = () => {
  return (
    <div className='privacy-wrapper'>
      <div className='privacy-header'>
        <div className='logo unselectable'>
          <Link to='/'>
            <ProgressiveImage
              preview={logoLowRes}
              src={logo}
              render={(src: string) => <img src={src} className='logo__image' alt='Readthisover logo'/>}
            />
          </Link>
        </div>

        <div className='privacy-header-title'>
        </div>
      </div>
      <h1>Privacy Policy</h1>
      <p>
        <h3>What information do we collect?</h3>
        When you join Readthisover, we ask for some information that will identify you to us as a user. This information includes your name, email address, Google username and other Google-specific profile information (we use react-google-login). We require your Google username and email to make it possible to assign Google docs to your account. Without these, Readthisover could not create and manage docs on your behalf.
      </p>

      <p>
        <h3>What do you do with this information?</h3>
        Readthisover never supplies your personal information to any third party for advertising or promotional purposes. If you owe money to Readthisover and we're unable to collect it, we may need to give your contact information to a third party for collection purposes. We will occasionally use your email address to send you information regarding Readthisover and the services we provide.
      </p>

      <p>
        <h3>Is my information secure?</h3>
        Your personal information is protected. Readthisover will never contact you unsolicited to ask for your account details. We have taken steps to ensure our databases are secure, and are confident in our ability to keep your information safe.
      </p>

      <p>
        <h3>What about cookies?</h3>
        Readthisover stores a cookie on your computer to track you while you are on the site. The information in this cookie is a string of characters that is meaningless to everyone and everything except our server. No personal information is stored in the cookie. The information in the cookie can not be used by third parties to gain access to your account information.
      </p>

      <p>
        <h3>Still have questions?</h3>
        We realize everyone has a different view on privacy. We take your privacy very seriously. If you still have concerns, or are unclear about any aspect of our privacy policy, please contact us, and we will be happy to discuss the matter with you.
      </p>
    </div>
  );
}
