import React, { FC } from 'react';
import { observer } from 'mobx-react';

import {
  Reviewer,
  ReviewerInviteStatus,
  ReviewablePost,
} from 'rto-shared';
import {
  PostPanelPreview,
  PostPanelReviewerView,
  PostPanelPendingView,
} from './PostPanel';

import { useUserStore, usePostStore } from '../stores/useStore';

import '../styles/DetailPanel.scss';

interface ReviewerPanelProps {
  reviewer: Reviewer;
  post: ReviewablePost;
  scrollRow: () => void;
}

const ReviewerPanel: FC<ReviewerPanelProps>  = observer(({
  reviewer,
  post,
  scrollRow,
}) => {
  const { id } = post;
  const { invite_status } = reviewer;

  console.log(invite_status);
  if (invite_status === ReviewerInviteStatus.PENDING) {
    return <PostPanelPendingView postId={post.id} reviewer={reviewer}/>;
  }
  return (
    <PostPanelReviewerView
      postId={id}
      key={id}
      scrollRow={scrollRow}
    />
  );
});

interface DetailPanelProps {
  id: string;
  scrollRow: () => void;
}

export const DetailPanel: FC<DetailPanelProps> = observer(({
  id,
  scrollRow,
}) => {
  const postStore = usePostStore();
  const userStore = useUserStore();

  const rowData = postStore.posts[id];

  const maybeReview = rowData.reviewers.filter((rev: Reviewer) => {
    return userStore.user && rev.user_id === userStore.user.uid;
  });

  const role = maybeReview.length > 0 ? 'REVIEWER' : 'REVIEWEE';

  if (role === 'REVIEWER') {
    return (
      <ReviewerPanel
        reviewer={maybeReview[0]}
        post={rowData}
        scrollRow={scrollRow}
      />
    );
  }
  return (
    <PostPanelPreview
      scrollRow={scrollRow}
      postId={rowData.id}
      isOwner={true}
    />
  );
});

