import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Publish from '@material-ui/icons/Publish';
import OutlinedFlag  from '@material-ui/icons/OutlinedFlag';

import { PostPhase, Reviewer, ReviewerInviteStatus } from 'rto-shared';

import { useUserStore, usePostStore } from '../stores/useStore';
import { Relation } from '../stores/PostStore';
import { RowData } from './Grid';
import { CenteredTooltip } from './CenteredTooltip';
import createDocLink from '../createDocLink';

import googleDoc from '../images/gdocs.svg';

export const RowDisplay: FC<RowData> = observer(({ id }) => {
  const postStore = usePostStore();
  const userStore = useUserStore();
  const matches = useMediaQuery('(max-width:600px)');

  const post = postStore.posts[id];
  const { relation, pending } = postStore.getPostRelation(id);
  if (!userStore.user || !post || relation === Relation.NONE) {
    return null;
  }

  const isOwner = relation === Relation.OWNER;
  const { phase } = post;

  let reviewers: Reviewer[] = post.reviewers.filter(({ invite_status }: Reviewer) =>
    invite_status !== ReviewerInviteStatus.PENDING &&
    invite_status !== ReviewerInviteStatus.REJECTED);

  if (reviewers.length > 3) {
    reviewers = reviewers.slice(0, 3);
  }

  const hasRevs = reviewers.length > 0;
  const docLink = createDocLink(post.google_docs_id);
  const docSize = matches ? '20px' : '30px';
  const avaSize = matches ? '28px' : '42px';
  const iconSize = matches ? '32px' : '52px';
  const rowClass = `row-display ${hasRevs ? 'row-min-height' : ''}`;

  return (
    <div className={rowClass}>
      {
        !pending && (
          <CenteredTooltip title='GDoc link'>
            <a href={docLink}>
              <img src={googleDoc}
                   alt='Google docs logo'
                   style={{ width: docSize }}
              />
            </a>
          </CenteredTooltip>
        )
      }
      {
        pending && (
          <div style={{
            height: '100%',
            minHeight: '100%',
            paddingLeft: '12px',
            visibility: 'hidden',
            minWidth: 48,
          }}>
            <img src={googleDoc}
                 alt='Google docs logo'
                 style={{ width: docSize }}
            />
          </div>

        )
      }
      {
        (isOwner && phase === PostPhase.UNPUBLISHED) && (
          <CenteredTooltip title='Publish me'>
            <Link to={`/get-reviewed?postId=${id}`}>
              <Publish style={{ fontSize: iconSize}}/>
            </Link>
          </CenteredTooltip>
        )
      }
      {
        phase === PostPhase.COMPLETED && (
          <CenteredTooltip title={
            isOwner ? 'Post reviewed' : 'Review finished'
          }>
            <OutlinedFlag />
          </CenteredTooltip>
        )
      }
      {
        reviewers.length > 0 && (
          <div style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '7px',
          }}>
            {
              reviewers.map(({ photo_url, invited_email }, idx) => {
                return (
                  <CenteredTooltip title={invited_email} key={invited_email}>
                    <img
                      key={invited_email}
                      alt='Reviewer avatar'
                      className='reviewer-avatar-display'
                      style={{
                        width: avaSize,
                        position: 'absolute',
                        right: `${(idx * 20)}%`,
                      }}
                      src={photo_url || ''}
                    />
                  </CenteredTooltip>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
})

