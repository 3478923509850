"use strict";

var _defineProperty = require("/home/ubuntu/readthisover/ts_frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _t$keyof, _t$keyof2;

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = __importStar(require("io-ts"));

var __1 = require("../");

var optional_1 = require("../optional");

var PostPhase;

(function (PostPhase) {
  PostPhase["UNPUBLISHED"] = "UNPUBLISHED";
  PostPhase["PUBLISHED"] = "PUBLISHED";
  PostPhase["REMOVED"] = "REMOVED";
  PostPhase["COMPLETED"] = "COMPLETED";
})(PostPhase = exports.PostPhase || (exports.PostPhase = {}));

exports.PostPhaseV = t.keyof((_t$keyof = {}, _defineProperty(_t$keyof, PostPhase.UNPUBLISHED, null), _defineProperty(_t$keyof, PostPhase.PUBLISHED, null), _defineProperty(_t$keyof, PostPhase.REMOVED, null), _defineProperty(_t$keyof, PostPhase.COMPLETED, null), _t$keyof));
var ReviewerInviteStatus;

(function (ReviewerInviteStatus) {
  ReviewerInviteStatus["PENDING"] = "PENDING";
  ReviewerInviteStatus["ACCEPTED"] = "ACCEPTED";
  ReviewerInviteStatus["REJECTED"] = "REJECTED";
  ReviewerInviteStatus["REMOVED"] = "REMOVED";
})(ReviewerInviteStatus = exports.ReviewerInviteStatus || (exports.ReviewerInviteStatus = {}));

exports.ReviewerInviteStatusV = t.keyof((_t$keyof2 = {}, _defineProperty(_t$keyof2, ReviewerInviteStatus.PENDING, null), _defineProperty(_t$keyof2, ReviewerInviteStatus.ACCEPTED, null), _defineProperty(_t$keyof2, ReviewerInviteStatus.REJECTED, null), _defineProperty(_t$keyof2, ReviewerInviteStatus.REMOVED, null), _t$keyof2));
exports.ReviewerV = optional_1.fixOptionals(t.type({
  id: t.string,
  user_id: t.union([t.string, optional_1.Nullable]),
  display_name: t.union([t.string, optional_1.Nullable]),
  photo_url: t.union([t.string, optional_1.Nullable]),
  invited_email: t.string,
  invite_status: exports.ReviewerInviteStatusV,
  owner_email: t.string,
  reviews: t.array(__1.ReviewV)
}));
exports.ReviewablePostV = t.intersection([__1.BasicPostV, t.type({
  id: t.string,
  owner_id: t.string,
  star_value: t.number,
  google_docs_id: t.string,
  time_created: t.string,
  phase: exports.PostPhaseV,
  reviewers: t.array(exports.ReviewerV)
})]);