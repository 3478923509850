import React, {
  FC,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import Fuse from 'fuse.js';


import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import UpArrow from '@material-ui/icons/ArrowUpward';
import DownArrow from '@material-ui/icons/ArrowDownward';

import Pagination from '@material-ui/lab/Pagination';
import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  getSorting,
  Order,
  RowData,
  stableSort,
} from './GridData';
import { SearchBar } from './SearchBar';

import '../../styles/Grid.scss';

export interface DetailProps {
  id: string;
  scrollRow: () => void;
}

export interface ExpandableRowProps {
  row: RowData;
  detailEnabled?: boolean;
  open?: boolean;
  setRowOpen?: (id: string) => void;
  onCollapseFinish?: (id: string) => Promise<void>;
  DetailComponent?: React.ElementType<DetailProps>;
  RowDisplayComponent?: React.ElementType<RowData>;
}

export interface GridRowProps {
  row: RowData;
  detailEnabled?: boolean;
  open?: boolean;
  setRowOpen?: (id: string) => void;
  onCollapseFinish?: (id: string) => Promise<void>;
  DetailComponent?: React.ElementType<DetailProps>;
  RowDisplayComponent?: React.ElementType<RowData>;
  scrollRow: () => void;
}

export const GridRow: FC<GridRowProps> = observer(({
  row,
  open,
  RowDisplayComponent,
  DetailComponent,
  onCollapseFinish,
  scrollRow,
  setRowOpen,
}) => {
  const matches = useMediaQuery('(max-width:600px)');
  let title = row.title;
  if (row.title.length > 13 && matches) {
    title = `${title.slice(0, 13)}...`;
  }

  return (
    <>
      <div id={row.id} className='rowgrid' onClick={(evt) => {
        evt.preventDefault();
        setRowOpen?.(row.id);
      }}>
        <div className='rowgrid-title text__config'>
          {title}
        </div>
        <div className='rowgrid-content'>
          {
              RowDisplayComponent &&
              <RowDisplayComponent {...row}/>
          }
        </div>
        <div className='rowgrid-expand-control'>
          {
            open ?
              <ExpandMore />
            :
              <ChevronRight />
          }
        </div>
      </div>
      <div id={`${row.id}-detail`}>
        <Collapse
          in={open}
          timeout='auto'
          onEntered={() => {
            onCollapseFinish?.(row.id);
          }}
          mountOnEnter
          unmountOnExit
        >
          {
            DetailComponent && (
              <DetailComponent id={row.id} scrollRow={scrollRow}/>
            )
          }
        </Collapse>
      </div>
    </>
  );
});

const rowsPerPage = 10;

export interface GridProps {
  rows: RowData[];
  detailEnabled?: boolean;
  DetailComponent?: React.ElementType<DetailProps>;
  RowDisplayComponent?: React.ElementType<RowData>;
  openRow?: string;
  onCollapseFinish?: (id?: string) => Promise<void>;
  setOpenRow?: (id?: string) => void;
  setSearchRow?: (id: string) => void;
  scrollRow: () => void;
}

const fuseOptions = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'title',
  ]
};

export const Grid: FC<GridProps> = observer(({
  rows,
  openRow,
  setOpenRow,
  onCollapseFinish,
  detailEnabled,
  DetailComponent,
  RowDisplayComponent,
  scrollRow,
}) => {
  const fuse = new Fuse(rows, fuseOptions);
  const [currPage, setCurrPage] = useState<number>(0);
  const numPages = Math.floor(rows.length / rowsPerPage);
  const [oldRows, setOldRows] = useState<RowData[]>(rows);
  const [currSearch, setSearch] = useState<string | undefined>(undefined);
  const [searchRows, setSearchRows] = useState<RowData[]>(rows);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy] = useState<keyof RowData>('title');

  const setRowOpen = (id: string): void => {
    setOpenRow && setOpenRow(openRow === id ? undefined : id);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>) => {
    const isDesc = order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  };

  const searchUpdate = (value?: string) => {
    if (!value && value !== currSearch) {
      setSearchRows(rows);
      setSearch(undefined);
    } else if (value !== currSearch) {
      setOpenRow?.(undefined);
      const hasSearch = value === '' ? undefined : value;
      setSearch(hasSearch);
      setSearchRows(hasSearch ? fuse.search(hasSearch) : rows);
    }
  };

  if (oldRows !== rows) {
    setOldRows(rows);
    setSearchRows(currSearch ? fuse.search(currSearch) : rows);
  }

  const gridClass = numPages > 1 ? 'grid-with-pag' : 'grid-minus-pag';

  return (
    <div className={`grid ${gridClass}`}>
      <div className='grid-header'>
        <div className='grid-header-title' onClick={handleRequestSort}>
          <div className='grid-header-title-text text__config'>
            Title
          </div>
          {
            order === 'asc' ?
              <UpArrow
                className='grid-header-title-icon'
                fontSize='small'
              />
            :
              <DownArrow
                className='grid-header-title-icon'
                fontSize='small'
              />
          }
        </div>
        <div className='grid-header-search'>
          <SearchBar
            placeHolder='Search posts'
            currSearch={currSearch}
            onChange={searchUpdate}
          />
        </div>
      </div>
      <div className='grid-content' id='main-table'>
        {
          !currSearch ?
            stableSort(searchRows, getSorting(order, orderBy))
              .slice(currPage * rowsPerPage, currPage * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <GridRow
                    key={row.id}
                    row={row}
                    detailEnabled={detailEnabled || false}
                    open={openRow === row.id}
                    setRowOpen={setRowOpen}
                    onCollapseFinish={onCollapseFinish}
                    DetailComponent={DetailComponent}
                    RowDisplayComponent={RowDisplayComponent}
                    scrollRow={scrollRow}
                  />
                );
              })
          :
            searchRows.map((row, index) => {
              return (
                <GridRow
                  key={row.id}
                  row={row}
                  detailEnabled={detailEnabled || false}
                  open={openRow === row.id}
                  setRowOpen={setRowOpen}
                  onCollapseFinish={onCollapseFinish}
                  DetailComponent={DetailComponent}
                  RowDisplayComponent={RowDisplayComponent}
                  scrollRow={scrollRow}
                />
              );
            })
        }
      </div>
      {
        numPages > 1 ?
          <div className='grid-pagination'>
            <Pagination
              count={numPages}
              page={currPage}
              onChange={(_: any, page: number) => setCurrPage(page)}
            />
          </div>
        :
          <div className='grid-pagination'/>
      }
    </div>
  );
});
