import React, {
  useEffect,
  KeyboardEvent,
} from 'react';
import { observer } from 'mobx-react'

import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import lightGreen from '@material-ui/core/colors/lightGreen';

import Routes from './Routes';

import './styles/App.scss';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: lightGreen.A700,
      light: lightGreen.A200,
      dark: lightGreen[800],
    },
  },
  typography: {
    fontFamily: [
     '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const handleMouseDownOnce = () => {
  document.body.classList.remove('user-is-tabbing');
  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab as any);
}

const handleFirstTab = (e: KeyboardEvent<any>) => {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown',
      handleFirstTab as any);
    window.addEventListener('mousedown',
      handleMouseDownOnce as any);
  }
}

export default observer(() => {
  useEffect(() => {
    window.addEventListener('keydown',
      handleFirstTab as any);
    window.onresize = () => {
      document.body.style.height = `${window.innerHeight}`;
    }
    window.onresize({} as any); // called to initially set the height.
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <Routes/>
      </div>
    </ThemeProvider>
  );
});
