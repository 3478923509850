import React, {
  FC,
  KeyboardEvent,
  ChangeEvent,
  CSSProperties,
} from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import '../../styles/PostForm.scss';

interface FormTitleProps {
  disabled: boolean;
  label: string;
  required: boolean;
  error?: boolean;
  value?: string;
  validated?: boolean;
  helperText?: string;
  onChange?: (event: ChangeEvent) => void;
  placeHolder?: string;
  autoComplete?: string;
  onKeyPress?: (event: KeyboardEvent) => void;
  size?: 'small' | 'medium' | undefined;
  style?: CSSProperties;
}

const ValidationTextField = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  },
})(TextField);

export const FormTitle: FC<FormTitleProps> = ({
  disabled,
  value,
  label,
  error,
  style,
  size,
  required,
  validated,
  helperText,
  onChange,
  placeHolder,
  autoComplete,
  onKeyPress,
}) =>
  validated ?
    <ValidationTextField
      label={label}
      error={error}
      placeholder={placeHolder || 'A post about penguins'}
      disabled={disabled}
      onChange={onChange}
      required={required}
      fullWidth={true}
      margin='normal'
      variant='outlined'
      value={value || ''}
      autoComplete={autoComplete || 'off'}
      onKeyPress={onKeyPress}
      helperText={helperText}
      size={size}
      style={style}
    />
  :
    <TextField
      label={label}
      error={error}
      onChange={onChange}
      placeholder={placeHolder || 'A post about penguins'}
      disabled={disabled}
      required={required}
      fullWidth={true}
      margin='normal'
      variant='outlined'
      value={value || ''}
      autoComplete={autoComplete || 'off'}
      onKeyPress={onKeyPress}
      helperText={helperText}
      size={size}
      style={style}
    />
