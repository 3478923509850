import React, { Component } from 'react';
import { RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react'

import qs from 'qs';

import { BasicPost, PostPhase, ReviewablePost } from 'rto-shared';

import withStore from '../stores/withStore';
import { ConfigPage } from './ConfigPage';

import { PaddedTextButton } from '../components/PaddedTextButton';

import { FormTitle } from '../components/post_creation/';

import msleep from '../msleep';

import '../styles/PostForm.scss';

const { FAKE_RUN } = process.env;

const testPost: ReviewablePost = {
  title: "Another test",
  is_public: false,
  accelerated: false,
  google_docs_id: "1Zb4abrYMx_5Qw1zn9UxAKtC5-1j3loM6Bmiwypq_HgY",
  id: "7317ca31-b872-4fb4-b74a-8673956bf296",
  owner_id: "0cb9ee6b-3759-4947-9986-25494f2053ee",
  // review_tags: [],
  star_value: 0,
  time_created: "2019-08-12T15:22:23.337Z",
  time_in_minutes: 240,
  phase: PostPhase.UNPUBLISHED,
  reviewers: [],
};

// TODO: Add props and state
// TODO: Add rules about submitting for review conduct
@observer
class CreateAPost extends Component<any, any> {
  constructor(props: RouteProps) {
    super(props);
    this.state = {
      isTransitioning: false,
      validated: false,
      titleValue: undefined,
      copyDocValue: undefined,
    };
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = document.getElementById('configform') as any;
    if (form === null) {
      return;
    }
    if (form.checkValidity()) {
      if (this.props.userStore.user !== undefined) {
        // TODO: Actually think about this default config for a sec
        const post: BasicPost = {
          title: this.state.titleValue,
          is_public: false,
          accelerated: false,
          // review_tags: [],
          time_in_minutes: 240,
        };
        this.setState({ isTransitioning: true });
        let res;
        if (FAKE_RUN) {
          await msleep(4500);
          res = testPost;
        } else {
          try {
            const maybeValue = this.state.copyDocValue;
            res = await this.props.postStore.createPost(post, maybeValue);
          } catch (err) {
            this.setState({ isTransitioning: false });
            return;
          }
        }
        // TODO(Ry): update to also cache the post
        const search = qs.stringify({ postId: res.id });
        this.props.history.push({
          pathname: '/get-reviewed',
          search,
        });
      } else {
        throw new Error('Cannot handle undefined user');
      }
    } else {
      this.setState({
        validated: true,
      });
    }
  }

  render() {
    return (
      <ConfigPage isTransitioning={this.state.isTransitioning}
                  title='Create a Post'
      >
        <div className='post-form'>
          <form noValidate
                id='configform'
                onSubmit={this.handleSubmit}
          >
            <FormTitle disabled={this.state.isTransitioning}
                       label='Post title'
                       required={true}
                       validated={this.state.validated}
                       onChange={(e: any) => this.setState({ titleValue: e.target.value})}
                       value={this.state.titleValue}
            />
            <FormTitle disabled={this.state.isTransitioning}
                       validated={this.state.validated}
                       required={false}
                       label='Existing doc URL (Optional)'
                       onChange={(e: any) => this.setState({ copyDocValue: e.target.value})}
                       value={this.state.copyDocValue}
                       placeHolder='https://docs.google.com/document/d/1Ja1XHskyKSW3YyIH_2VuiGz2zLhWxqJRUPSWh9rZ6rQ/'
            />
            <div className='post-form-submission'>
              <PaddedTextButton buttonText='Create a Post'
                                onClick={this.handleSubmit}
                                onKeyDown={this.handleSubmit}
                                disabled={this.state.isTransitioning}
              />
            </div>
          </form>
        </div>
      </ConfigPage>
    );
  }
}

export default withStore(CreateAPost);
