import { ReviewType } from 'rto-shared';

import { CheckOption } from '../components/FormCheckGroup';

export function getCheckState(rT: ReviewType): CheckOption[] {
  return [
    {
      id: 'spellcheck',
      display: 'Spell Check',
      checked: rT.spell_check,
    },
    {
      id: 'grammarcheck',
      display: 'Grammar Check',
      checked: rT.grammar_check,
    },
    {
      id: 'factcheck',
      display: 'Fact Check',
      checked: rT.fact_check,
    },
    {
      id: 'highlevelcheck',
      display: 'High Level Check',
      checked: rT.high_level_check,
    },
    {
      id: 'technicalcheck',
      display: 'Technical Check',
      checked: rT.technical_check,
    },
  ];
}

export function getReviewType(checkState: CheckOption[]): ReviewType {
  const initReview: ReviewType = {
    spell_check: false,
    grammar_check: false,
    fact_check: false,
    high_level_check: false,
    technical_check: false,
  };

  // eslint-disable-next-line
  checkState.map(({ checked, id }: any) => {
    if (checked === true) {
      if (id === 'spellcheck') {
        initReview.spell_check = true;
      } else if (id === 'grammarcheck') {
        initReview.grammar_check = true;
      } else if (id === 'factcheck') {
        initReview.fact_check = true;
      } else if (id === 'highlevelcheck') {
        initReview.high_level_check = true;
      } else if (id === 'technicalcheck') {
        initReview.technical_check = true;
      }
    }
  });
  return initReview;
}
