import React from 'react';
import { StoreContext } from './useStore';

export default function withStore(Component: any) {
    return function WrapperComponent(props: any) {
        return (
            <StoreContext.Consumer>
              { 
                ({ errorStore, userStore, postStore }: any) =>
                  <Component {...props} errorStore={errorStore} userStore={userStore} postStore={postStore} />
              }
            </StoreContext.Consumer>
        );
    };
}
