import React, { Component } from 'react';
import { RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react'

import withStore from '../stores/withStore';

import { getReviewType } from './reviewTypeUtil';
import { ConfigPage } from './ConfigPage';
import { Validity, CheckOption } from '../components/FormCheckGroup';

import { PaddedTextButton } from '../components/PaddedTextButton';

import { FormCheck } from '../components/post_creation/';

import '../styles/PostForm.scss';

const initialCheckState = [
  {
    id: 'spellcheck',
    display: 'Spell Check',
    checked: false,
  },
  {
    id: 'grammarcheck',
    display: 'Grammar Check',
    checked: false,
  },
  {
    id: 'factcheck',
    display: 'Fact Check',
    checked: false,
  },
  {
    id: 'highlevelcheck',
    display: 'High Level Check',
    checked: false,
  },
  {
    id: 'technicalcheck',
    display: 'Technical Check',
    checked: false,
  },
];

// TODO: Add props and state
// TODO: Add rules about submitting for review conduct
@observer
class ReviewAPost extends Component<any, any> {
  constructor(props: RouteProps) {
    super(props);
    this.state = {
      isTransitioning: false,
      validated: false,
      checkValidity: Validity.DEFAULT,
      checkState: initialCheckState,
    };
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { checkValidity } = this.state;
    const form = document.getElementById('configform') as any;
    if (form === null) {
      return;
    }
    if (form.checkValidity() && (checkValidity === Validity.VALID)) {
      if (this.props.userStore.user) {
        const reviewOptions = {
          review_types: getReviewType(this.state.checkState),
          tags: [],
          allow_partial_matches: true,
        };
        console.log(reviewOptions);
        // TODO: Actually think about this default config for a sec
        this.setState({ isTransitioning: true });
        const res = await this.props.postStore.reviewPost(reviewOptions);
        this.props.userStore.addReviewToUser(res.id);
        // TODO: do post thing here
        this.props.history.push('/status-page');
      } else {
        throw new Error('Cannot handle undefined user');
      }
    } else {
      this.setState({
        validated: true,
        checkValidity: (checkValidity === Validity.VALID ?
                          checkValidity : Validity.INVALID),
      });
    }
  }

  render() {
    return (
      <ConfigPage isTransitioning={this.state.isTransitioning}
                  title='Review a Post'
      >
        <div className='post-form'>
          <form noValidate
                id='configform'
                onSubmit={this.handleSubmit}
          >
            <FormCheck checkState={this.state.checkState}
                       validity={this.state.checkValidity}
                       disabled={this.state.isTransitioning}
                       validated={this.state.validated}
                       setCheckState={(cS: CheckOption[], cV: Validity) => {
                          this.setState({ checkState: cS, checkValidity: cV });
                       }}
            />
            <div className='post-form-submission'>
              <PaddedTextButton buttonText='Review'
                                onClick={this.handleSubmit}
                                onKeyDown={this.handleSubmit}
                                disabled={this.state.isTransitioning}
              />
            </div>
          </form>
        </div>
      </ConfigPage>
    );
  }
}

export default withStore(ReviewAPost);
