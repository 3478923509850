import React, {
  useEffect,
  useState
} from 'react';
import { observer } from 'mobx-react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { useUserStore, usePostStore } from '../stores/useStore';
import { RowDisplay } from './RowDisplay';
import { DetailPanel } from './DetailPanel';
import { Grid, RowData } from './Grid';

import '../styles/DataTable.scss';

export interface DataTableProps {
  row?: string;
  setSearchRow: any;
  data: RowData[];
}

export const DataTable: React.SFC<DataTableProps> = observer(({
  data,
  row,
  setSearchRow,
}: DataTableProps) => {
  const [openRow, setOpenRow] = useState<string | undefined>(row);

  const scrollOpenRow = async (id?: string): Promise<void> => {
    if (!id || openRow !== id) {
      return;
    }

    const openChild = document.getElementById(`${id}-detail`);
    if (!openChild) {
      return;
    }

    scrollIntoView(openChild, {
      scrollMode: 'if-needed',
      block: 'nearest',
      inline: 'nearest',
    });
  }

  useEffect(() => {
    scrollOpenRow(openRow);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollRow = () => {
    scrollOpenRow(openRow);
  }

  const setOpenWrap = (id?: string) => {
    setSearchRow(id);
    setOpenRow(id);
  }

  return (
    <Grid
      rows={data}
      scrollRow={scrollRow}
      DetailComponent={DetailPanel}
      RowDisplayComponent={RowDisplay}
      setOpenRow={setOpenWrap}
      detailEnabled={true}
      openRow={openRow}
      onCollapseFinish={scrollOpenRow}
    />
  );
});

export const PostsTable: React.SFC<any> = observer((props: any) => {
  const postStore = usePostStore();
  return <DataTable data={postStore.getUserPosts} {...props}/>;
});

export const ReviewsTable: React.SFC<any> = observer((props: any) => {
  const postStore = usePostStore();
  const userStore = useUserStore();

  if (userStore.user === undefined) {
    return null;
  }

  const pendingAlteredTitle = postStore.getPendingReviews.map((post) =>
    ({ ...post, title: `${post.title} (pending)` }));
  const allReviews = [
    ...postStore.getUserReviews,
    ...pendingAlteredTitle,
  ];

  return (<DataTable data={allReviews} {...props}/>);
});
