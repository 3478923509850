import React, { useState, useEffect } from 'react';
import '../styles/Sharpener.scss';

import pencil from '../images/basic-pencil.svg';
import { Sh0, Sh1, Sh2 } from '../images/Shavings';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

let buildup = 0;

interface Shaving {
  bottom: string;
  left: string;
  width: string;
  height: string;
  rot: string;
  img: any;
  key: any;
}

const initDelay = 1300;
const totalTime = 14000;
const addTime = 300;

function getBotRange(): string {
  const makePair = (floor: number, ceil: number) => ({ floor, ceil });
  const ranges = [
    makePair(95, 100),
    makePair(90, 100),
    makePair(80, 90),
    makePair(70, 80),
    makePair(60, 70),
    makePair(50, 60),
    makePair(30, 50),
    makePair(10, 30),
    makePair(0, 10),
  ];
  let sel = Math.floor((buildup / totalTime) * ranges.length)
  sel = (sel >= 0 && sel < ranges.length) ? sel : 0;
  const { floor, ceil } = ranges[sel];
  const randPart = getRandomInt(ceil - floor);
  return `${randPart + floor}%`;
}

function makeShaving(): Shaving {
  const randBot = `${getBotRange()}`;
  const randLeft = `${getRandomInt(100) + 5}%`;
  const randImage = getRandomInt(3);
  const randRot = `${getRandomInt(360)}deg`;
  const key = getRandomInt(100000000000);
  return {
    bottom: randBot,
    left: randLeft,
    width: '2',
    height: '2',
    rot: randRot,
    img: randImage,
    key,
  };
}



export default () => {
  const [shavings, setShavings] = useState<Shaving[]>([]);

  useEffect(() => {
    let addShaving: any;
    const add = () => {
      if (addShaving) {
        clearInterval(addShaving);
        buildup = 0;
      }
      setTimeout(() => {
        addShaving = setInterval(() => {
          buildup += addTime;
          setShavings((oldShavings) => {
            return [
              ...oldShavings,
              makeShaving(),
              makeShaving(),
              makeShaving(),
              makeShaving(),
              makeShaving(),
              makeShaving(),
            ];
          });
        }, 300);
      }, initDelay);
    }
    const outerAdd = setInterval(add, totalTime);
    add();
    const clearShavings = setInterval(() => {
      setShavings([]);
    }, totalTime);
    return () => {
      clearInterval(outerAdd);
      clearInterval(addShaving);
      clearInterval(clearShavings);
    }
  }, []);

  return (
    <div className='container'>
      <div className='sharpener'>
        <div className='animation-container'>
          <div className='animation-shavings'>
            <svg
             height="100%"
             style={{
               fillRule: 'evenodd',
               clipRule: 'evenodd',
               strokeLinecap: 'round',
               strokeLinejoin: 'round',
               position: 'absolute',
               top: 0,
               left: 0,
             }}
             xmlnsXlink="http://www.w3.org/1999/xlink"
             xmlns="http://www.w3.org/2000/svg"
             xmlSpace="preserve"
             width="100%"
             version="1.1"
             viewBox="0 0 5000 3000"
            >
              <defs>
                <Sh0 />
                <Sh1 />
                <Sh2 />
              </defs>
              {
                shavings.map((shaving) => {
                  return (
                    <use
                     key={shaving.key}
                     xlinkHref={`#sh${shaving.img}`}
                     style={{
                       transform: `
                         translate(${shaving.left}, ${shaving.bottom})
                         rotate(${shaving.rot})
                         scale(${shaving.width}, ${shaving.height})`,
                    }}/>
                  )
                })
              }
            </svg>
          </div>
          <div className='animation-nested'>
            <img
              src={pencil}
              className='animation-image'
              alt='pencil animation'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
