import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import Sharpener from '../components/Sharpener';

import '../styles/ConfigPage.scss';

interface ConfigPageProps extends RouteProps {
  title: string;
  isTransitioning: boolean;
}

export const ConfigPage: FC<ConfigPageProps> = observer(({
  title,
  isTransitioning,
  children,
}) => {
  return (
    <div className='config-page'>
      <div className='config-page-fold inner-margin'>
          {isTransitioning && (
              <div className='basic-fold-blocker'>
                {
                  isTransitioning &&
                    <div className='basic-fold-blocker-spinner'>
                      <Sharpener />
                    </div>
                }
              </div>
            )
          }
        <div className='config-page-content-title unselectable'>
          {title}
        </div>
        <div className='config-page-content-body'>
          {children}
        </div>
      </div>
    </div>
  );
})
