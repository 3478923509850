import React, { useCallback, useState } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';

import { GoogleAuthButton } from '../components/GoogleAuthButton';
import TrifoldItem from '../containers/TrifoldItem';
import Trifold from '../containers/Trifold';

import ProgressiveImage from '../components/ProgressiveImage';

import '../styles/Landing.scss';

import logo from '../images/finallogo.svg';
import logoLowRes from '../images/lowreslogo.png';
import star from '../images/star.svg';
import pen from '../images/pen2.svg';
import pencilLowRes from '../images/simple-pencil.svg';
import pencil from '../images/pencil2.svg';
import comment from '../images/comment-min.png';
import commentLowRes from '../images/compress.png';
import googleDoc from '../images/gdocs.svg';
import blog from '../images/blog.png';
import config from '../images/config.png';
import glasses from '../images/glasses.svg';


const LandingSpacer = () => {
  return <div className='landing__spacer' />
}

const headline = 'Typos cost you readers';
const delivery0 =
`Readthisover is a hub for content creators and reviewers. Trade, buy and sell content reviews.`;

const getReviewedStar =
`Spend stars or cash to start the content review process. Don't worry, the first few are on us.`;

const getReviewedGoogleDocs =
`Add the contents of your blog post to the provided Google doc. Alternatively, provide a Google doc of your own.`;

const getReviewedBlogPost = 'Once everything is "just right" (although not too right, this is a review site), submit your content for review. After a short wait, you will be matched with a reviewer.';

const reviewConfig =
`Reviewing starts by setting your reviewer preferences. That way, you only get matched with content appropriate for your skills.`;

const reviewGlasses =
`Carefully and thoughtfully review the content you were assigned. Remember, ReadThisOver is about helping everyone improve their writing. That means staying constructive and positive.`;

const reviewStar =
`Once you've finished reviewing the content, you'll be rewarded with stars or cash, depending on how you configured your review.`;


const Landing: React.FC<RouteComponentProps> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const onFailure = useCallback((response: any) => {
    console.log('failure');
    console.log(response);
  }, []);

  const inner = (
    <div className='landing'>
      <div className='landing__logo unselectable'>
        <ProgressiveImage
          preview={logoLowRes}
          src={logo}
          render={(src: string) => <img src={src} className='landing__logo__image' alt='Readthisover logo'/>}
        />
      </div>
        <div className='landing__topfold landing__fold'>
          <div className='landing__topfold__attention text__intense'>
            <span>
              {headline}
            </span>
          </div>
          <div className='landing__topfold__comment unselectable'>
            <ProgressiveImage
              preview={commentLowRes}
              src={comment}
              render={(src: string) => <img src={src} className='landing__topfold__comment__image' alt='A negative Reddit comment about the authors post'/>}
            />
          </div>
          <div className='landing__topfold__message text__strong'>
            <span>
              {delivery0}
            </span>
          </div>
          <div className='landing-button'>
            <GoogleAuthButton buttonText='Get Your Content Reviewed!'
                              displayTextIfAuthenticated={true}
                              onFailure={onFailure}
                              nextRoute='/create-a-post'
                              history={history}
                              disabled={loading}
                              loading={loading}
                              setLoading={setLoading}
            />
          </div>
        </div>
        <LandingSpacer />
        <div className='landing__pencil unselectable'>
          <ProgressiveImage
            preview={pencilLowRes}
            src={pencil}
            render={(src: string) => <img src={src} className='landing__pencil__image' alt='A Pencil'/>}
          />
        </div>
        <LandingSpacer />
        <Trifold attentionText='Get Content Reviewed'
                 button={(
                  <div className='landing-button'>
                    <GoogleAuthButton buttonText='Create a Post'
                                      displayTextIfAuthenticated={true}
                                      onFailure={onFailure}
                                      nextRoute='/create-a-post'
                                      history={history}
                                      disabled={loading}
                                      loading={loading}
                                      setLoading={setLoading}
                    />
                  </div>
                )}
        >
          <TrifoldItem image={star}
                       imageAlt='A star'
                       imageClass='star'
                       foldText={getReviewedStar}
          />
          <TrifoldItem image={googleDoc}
                       imageClass='gdocs'
                       imageAlt='Google docs logo'
                       foldText={getReviewedGoogleDocs}
          />
          <TrifoldItem image={blog}
                       imageClass='blog'
                       imageAlt='Fake blog post'
                       foldText={getReviewedBlogPost}
          />
        </Trifold>
        <LandingSpacer />
        <div className='landing__pencil landing__pen unselectable'>
          <img className='landing__pencil__image landing__pen__image'
               src={pen}
               alt='Pen'
          />
        </div>
        <LandingSpacer />
        <Trifold attentionText='Review Content'
                 button={(
                  <div className='landing-button'>
                    <GoogleAuthButton buttonText='Review a Post'
                                      displayTextIfAuthenticated={true}
                                      onFailure={onFailure}
                                      nextRoute='/review-a-post'
                                      history={history}
                                      disabled={loading}
                                      loading={loading}
                                      setLoading={setLoading}
                    />
                  </div>
                 )}

        >
          <TrifoldItem image={config}
                       imageClass='config'
                       imageAlt='A configuration panel'
                       foldText={reviewConfig}
          />
          <TrifoldItem image={glasses}
                       imageClass='glasses'
                       imageAlt='A pair of glasses'
                       foldText={reviewGlasses}
          />
          <TrifoldItem image={star}
                       imageClass='star'
                       imageAlt='A star'
                       foldText={reviewStar}
          />
        </Trifold>
        <LandingSpacer />
      <div className='landing-bottom-nav'>
        <Link to='/privacy'>
          Privacy Policy
        </Link>
      </div>
    </div>
  );
  return inner;
}

export default Landing;
