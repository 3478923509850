import React from 'react';
import { observer } from 'mobx-react'

import { PaddedTextButton } from '../components/PaddedTextButton';

import { authRequest, Endpoint } from '../backend';

import { useUserStore, useErrorStore } from '../stores/useStore';

import '../styles/Admin.scss';

const numDocsToAdd = 50;

const Admin = () => {
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const addToDocsPool = async () => {
    try {
      if (userStore.user && userStore.user.token) {
        await authRequest(Endpoint.ADD_DOCS_TO_POOL,
          userStore.user.token, { numDocs: numDocsToAdd });
      }
    } catch (err) {
      console.error(err);
      errorStore.addError(err.message);
    }
  }
  return (
    <div className='admin'>
      <div className='admin-add-docs'>
        <PaddedTextButton buttonText='Add docs'
                          onClick={addToDocsPool}
                          onKeyDown={addToDocsPool}
        />
      </div>
    </div>
  );
}

export default observer(Admin);
