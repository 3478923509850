import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: 400,
      borderRadius: 4,
      backgroundColor: '#edf6fa',
      overflow: 'visible',
      // [theme.breakpoints.down('sm')]: {
      //   // marginTop: theme.spacing(2),
      //   width: '100%',
      // },
      marginLeft: 'auto',
    },
    input: {
      marginLeft: theme.spacing(1),
      color: 'rgba(0, 0, 0, .4)',
      flex: 1,
    },
    iconButton: {
      padding: 5,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

interface SearchBarProps {
  placeHolder?: string;
  currSearch?: string;
  onChange?: (value?: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeHolder,
  currSearch,
  onChange,
}) => {
  const classes = useStyles();
  const onChangeWrap = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    onChange && onChange(evt.target.value);
  }

  return (
    <Paper component='form' className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeHolder}
        inputProps={{ 'aria-label': placeHolder }}
        onChange={onChangeWrap}
        value={currSearch}
        onKeyPress={(ev: any) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
          }
        }}
      />
      <IconButton
        type='submit'
        className={classes.iconButton}
        aria-label='search'
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
