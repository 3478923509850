import React, {
  MouseEvent,
  ReactElement,
  FC,
} from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import Cancel from '@material-ui/icons/Block';
import Done from '@material-ui/icons/CheckCircle';
import Send from '@material-ui/icons/SendRounded';
import Sync from '@material-ui/icons/Sync';
import Launch from '@material-ui/icons/Launch';
import Delete from '@material-ui/icons/Delete';
import Gear from '@material-ui/icons/Settings';
import { IconButton } from '@material-ui/core';
import lightGreen from '@material-ui/core/colors/lightGreen';

import '../styles/text.scss';
import '../styles/Tooltips.scss';

const iconStyles = makeStyles({
  removeCircle: {
    borderRadius: '50%',
    background: 'radial-gradient(circle at center, #ffe83b 0, #ffe83b 60%, rgba(0,0, 0, 0) 60%)',
  },
  tooltip: {
    marginBottom: 0,
    bottom: 0,
  },
  done: {
    color: lightGreen.A700,
  },
  cancel: {
    color: '#ff5c5c',
  },
  deleteButton: {
    padding: 0,
    fontSize: '12px',
  },
});

export interface ClickableTooltipProps {
  onClick: (event: MouseEvent) => void;
}

export interface GenericTooltipProps {
  title: string;
  className?: string;
}

export const GenericTooltip: FC<GenericTooltipProps> = ({
  title,
  className,
  children,
}) => {
  const classes = iconStyles();
  const divCls = className || 'reviewstatus-icon';
  return (
    <div className={divCls}>
      <Tooltip
       title={title}
       placement='top'
       classes={{ tooltip: classes.tooltip }}
       enterTouchDelay={50}
      >
        {children as ReactElement}
      </Tooltip>
    </div>
  );
};

export const RemoveWithTooltip: FC = () => (
  <GenericTooltip title='Review in Progress'>
    <div className='remove-circle'/>
  </GenericTooltip>
);

export const CancelWithTooltip: FC = () => (
  <GenericTooltip title='Cancelled'>
    <Cancel fontSize='large'/>
  </GenericTooltip>
);


export const SendWithTooltip: FC = () => (
  <GenericTooltip title='Invite sent'>
    <Send fontSize='large'/>
  </GenericTooltip>
);

export const GearWithTooltip: FC<ClickableTooltipProps> = ({ onClick }) => (
  <GenericTooltip title='Reviewer settings' className='gear'>
    <IconButton onClick={onClick} className='gear-button'>
      <Gear fontSize='inherit'/>
    </IconButton>
  </GenericTooltip>
);

export const DoneWithTooltip: FC = () => (
  <GenericTooltip title='Finished reviewing'>
    <Done
      fontSize='large'
      className={iconStyles().done}
    />
  </GenericTooltip>
);

export const ChangesRequestedWithTooltip: FC = () => (
  <GenericTooltip title='Changes requested'>
    <Cancel
      fontSize='large'
      className={iconStyles().cancel}
    />
  </GenericTooltip>
);

const requestTitle = 'Request re-review';
export const RequestWithTooltip: FC<ClickableTooltipProps> = ({
  onClick,
}) => (
  <GenericTooltip
    title={requestTitle}
    className='request-review'
  >
    <IconButton onClick={onClick}>
      <div className='text__config'>
        {requestTitle}
      </div>
      <Sync fontSize='large'/>
    </IconButton>
  </GenericTooltip>
);


export const DeleteWithTooltip: FC<ClickableTooltipProps> = ({
  onClick,
}) => (
  <GenericTooltip
    title='Delete post'
    className='delete-post'
  >
    <IconButton
      onClick={onClick}
      className={iconStyles().deleteButton}
    >
      <Delete fontSize='small'/>
    </IconButton>
  </GenericTooltip>
);

interface LaunchProps {
  destination: string;
}

export const LaunchWithTooltip: FC<LaunchProps> = ({
  destination,
}) => (
  <GenericTooltip
    title='Open post in own page'
    className='right-col-icon'
  >
    <Link to={destination} className='launch-link'>
      <Launch fontSize='default' />
    </Link>
  </GenericTooltip>
);
