import React from 'react';

export const Sh0: React.SFC<any> = () => {
  return (
    <g id='sh0'>
      <path stroke="#000000" strokeOpacity="0" strokeWidth="1" d="M350+97.9397L23.5847+17.2297L23.5847+178.65L350+97.9397Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M350+97.9396L143.474+149.005L23.5847+178.65L63.4338+273.84L350+97.9396Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M349.713+97.9396L167.172+207.207L61.2062+270.638L127.167+350L349.713+97.9396Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M348.269+98.9568L328.929+110.917L317.702+117.86L324.69+126.546L348.269+98.9568Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M348.269+98.6664L332.58+101.615L323.472+103.327L324.585+112.774L348.269+98.6664Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M348.349+98.2564L328.223+93.8342L316.54+91.2671L312.716+103.156L348.349+98.2564Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M348.269+98.6664L61.2062+273.84L126.343+350L348.269+98.6664Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M348.269+98.6664L47.3513+192.414L67.0495+243.139L348.269+98.6664Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M335.871+97.2116L23.5847+26.2035L43.2829+178.65L335.871+97.2116Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>

    </g>
  );
}

export const Sh1: React.SFC<any> = () => {
  return (
    <g id='sh1'>
      <path stroke="#000000" strokeOpacity="0" strokeWidth="1" d="M17.6942+304.925L291.378+275.205L296.044+137.223L17.6942+304.925Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M17.6942+304.925L193.808+198.819L296.044+137.223L265.099+67.9053L17.6942+304.925Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M17.9368+304.838L175.452+156.234L266.89+69.9683L213.407+22.077L17.9368+304.838Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M19.1872+303.532L59.1851+269.16L82.4042+249.206L68.8515+237.846L19.1872+303.532Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M19.1788+303.78L61.769+282.969L86.493+270.889L84.363+248.723L19.1788+303.78Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M19.0994+304.154L56.7016+298.869L78.53+295.801L86.493+269.859L19.0994+304.154Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M19.1788+303.78L266.983+67.2316L214.104+21.8278L19.1788+303.78Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M19.1788+303.78L276.345+132.644L261.154+95.2416L19.1788+303.78Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M29.6209+301.274L291.637+267.534L279.387+143.18L29.6209+301.274Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
    </g>
  );
}

export const Sh2: React.SFC<any> = () => {
  return (
    <g id='sh2'>
      <path stroke="#000000" strokeOpacity="0" strokeWidth="1" d="M228.965+35.0889L58.1969+277.178L119.416+310.348L228.965+35.0889Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.965+35.0889L159.652+209.248L119.416+310.348L172.628+298.33L228.965+35.0889Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.842+35.3163L191.901+202.428L170.457+299.437L228.878+263.473L228.842+35.3163Z" fill="#f0c2a2" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.305+36.6696L221.176+70.0854L217.037+89.4836L228.946+82.4314L228.305+36.6696Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.497+36.6099L214.327+70.5485L206.101+90.2501L216.342+93.0076L228.497+36.6099Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.376+36.4624L208.715+66.4651L197.302+83.8819L202.765+94.7999L228.376+36.4624Z" fill="#000000" strokeLinecap="round" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.497+36.6099L171.671+300.095L228.524+264.126L228.497+36.6099Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M228.497+36.6099L134.841+294.343L162.537+289.156L228.497+36.6099Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
      <path stroke="#4c4c4c" strokeOpacity="0.0381738" strokeWidth="1" d="M222.622+46.1364L61.6002+279.022L127.874+294.738L222.622+46.1364Z" fill="#815d43" strokeLinecap="round" fillOpacity="0.103119" opacity="1" strokeLinejoin="round"/>
    </g>
  );
}
