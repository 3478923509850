import React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps
} from 'react-router-dom';

interface AppliedSFCProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export default ({ component: C, ...rest }: AppliedSFCProps) => {
  return (
    <Route
      {...rest}
      render={routeProps =>
        <C {...routeProps}/>
      }
    />
  );
};
