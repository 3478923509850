import React, { Component, ChangeEvent } from 'react';
import { observer } from 'mobx-react'

import qs from 'qs';

import { PostPhase } from 'rto-shared';

import withStore from '../stores/withStore';

import { FormTitle } from '../components/post_creation/';
import { PaddedTextButton } from '../components/PaddedTextButton';

import { ConfigPage } from './ConfigPage';

import {
  AddedReviewer,
  CheckConfig,
  CheckEntry,
} from '../components/AddedReviewer';

import createDocLink from '../createDocLink';
import { validateEmail } from '../validate';

import '../styles/PostForm.scss';
import '../styles/GetReviewed.scss';

import googleLogo from '../images/google_docs.png';


// TODO: Make proper component
function DocLinkSection({ id }: { id: string }) {
  const docLink = createDocLink(id);
  return (
    <a className='doc-link'
       href={docLink}
       target="_blank"
       rel="noopener noreferrer"
    >
      <span>Add the full content of your post here</span>
      <img className='docs-image' src={googleLogo} alt='Google Docs Logo'/>
    </a>
  );
}

// TODO: Add props and state
// TODO: Add rules about submitting for review conduct
@observer
class GetReviewed extends Component<any, any> {
  constructor(props: any) {
    super(props);
    const qp = qs.parse((props.location as any).search, { ignoreQueryPrefix: true });
    this.state = {
      postId: qp.postId,
      disabled: false,
      isTransitioning: false,
      validated: false,
      helperText: undefined,
      error: false,
      addedReviewer: '',
      addedReviewers: [],
    };
  }

  // TODO: Handle errors cases
  handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // this.setState({ post, disabled: post.phase !== PostPhase.UNPUBLISHED });
    const postId = this.state.postId;
    const user = this.props.userStore.user;
    if (!this.state.addedReviewers.length) {
      this.setState({
        validated: true,
        error: true,
        helperText: 'You must add at least one reviewer',
      });
    } else if (user !== undefined) {
      this.setState({ isTransitioning: true });
      const publishedPost = await this.props.postStore.publishPost(
        postId, this.state.addedReviewers);
      this.setState({ post: publishedPost });
      this.props.history.push('/status-page');
    } else {
      // throw new Error('Cannot handle undefined user');
    }
  }

  onKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      event.preventDefault();
      if (validateEmail(this.state.addedReviewer, this.props.userStore.user.email_address)) {
        let newReviewers = this.state.addedReviewers;
        const someReviewer = newReviewers.some(({ email }: CheckEntry) =>
          email === this.state.addedReviewer);
        if (!someReviewer) {
          newReviewers.push({
            email: this.state.addedReviewer,
            reviewType: {
              no_type: true,
              spell_check: false,
              grammar_check: false,
              fact_check: false,
              high_level_check: false,
              technical_check: false,
            },
          });
        }
        this.setState({
          error: false,
          validated: false,
          helperText: undefined,
          addedReviewer: '',
          addedReviewers: newReviewers,
        });
      } else {
        this.setState({
          helperText: 'You cannot review your own post',
          validated: true,
          error: true,
        });
      }
    }
  }

  handleReviewerRemoved = (removeEmail?: string) => {
    const newEmails = this.state.addedReviewers.filter(
      ({ email }: CheckEntry) => removeEmail !== email);
    this.setState({
      addedReviewers: newEmails,
    });
  }

  handleSetReviewState = (email: string, rState: CheckConfig) => {
    const copy = [...this.state.addedReviewers];
    copy.forEach((reviewer: CheckEntry) => {
      if (reviewer.email === email) {
        reviewer.reviewType = rState;
      }
    });
    this.setState({
      addedReviewers: copy,
    });
  }

  render() {
    const post = this.props.postStore.posts[this.state.postId];
    if (!post) {
      // TODO (Ry): Render "post not found"
      return null;
    }
    const {
      google_docs_id,
      title,
    } = post || {} as any;
    const disabled = (post.phase !== PostPhase.UNPUBLISHED);
    const hasReviewers = this.state.addedReviewers.length;

    const buttonText = 'Send to Reviewers';
    return (
      <ConfigPage isTransitioning={this.state.isTransitioning}
                  title='Get Reviewed'
      >
        <div className='post-form'>
          {
            post !== undefined ?
              <form
                noValidate
                onSubmit={this.handleSubmit}
                id='configform'
              >
                <FormTitle value={title}
                           disabled={true}
                           label='Post title'
                           required={true}
                />
                <DocLinkSection id={google_docs_id}/>
                <FormTitle disabled={this.state.isTransitioning || disabled}
                           validated={this.state.validated}
                           required={true}
                           label='Add a Reviewer'
                           onChange={(e: ChangeEvent) => {
                             const target = e.target as HTMLTextAreaElement;
                             this.setState({
                               addedReviewer: target.value
                             });
                           }}
                           value={this.state.addedReviewer}
                           placeHolder='tom@myspace.com'
                           onKeyPress={this.onKeyPress}
                           helperText={this.state.helperText}
                           error={this.state.error}
                />
                {
                  this.state.addedReviewers.length !== 0 && (
                    <>
                      <label className='text__info' style={{
                        marginBottom: '10px',
                        marginTop: '10px',
                      }}>
                        Added reviewers
                      </label>
                      <div className='added-reviewers'>
                        {
                          this.state.addedReviewers.map(({ email }: CheckEntry) => {
                            return (
                              <AddedReviewer
                                key={email}
                                email={email}
                                onRemove={this.handleReviewerRemoved}
                                setReviewState={this.handleSetReviewState}
                              />
                            );
                          })
                        }
                      </div>
                    </>
                  )
                }
                <div className='post-form-submission'>
                  <PaddedTextButton
                    buttonText={buttonText}
                    onClick={this.handleSubmit}
                    onKeyDown={this.handleSubmit}
                    disabled={
                      this.state.isTransitioning || disabled || !hasReviewers
                    }
                  />
                </div>
              </form>
            :
              null
          }
        </div>
     </ConfigPage>
    );
  }
}

export default withStore(GetReviewed);
