import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import AppliedSFCRoute from './components/AppliedSFCRoute';
import { PrivateRoute } from './components/PrivateRoute';
import { ExternalRoute } from './components/ExternalRoute';

import NotFound from './containers/NotFound';
import Landing from './containers/Landing';
import Login from './containers/Login';
import Admin from './containers/Admin';
import UserProfile from './containers/UserProfile';
import CreateAPost from './containers/CreateAPost';
import GetReviewed from './containers/GetReviewed';
import ReviewAPost from './containers/ReviewAPost';
import Test from './containers/Test';
import { Privacy } from './containers/Privacy';

import Sharpener from './components/Sharpener';

import './styles/Routes.scss';

export default () => {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <AppliedSFCRoute
          exact path='/'
          component={Landing}
        />
        <AppliedSFCRoute
          exact path='/login'
          component={Login}
        />
        <AppliedSFCRoute
          exact path='/review-invite/:postid'
          component={ExternalRoute}
        />
        <PrivateRoute
          exact path='/create-a-post'
          component={CreateAPost}
        />
        <PrivateRoute
          exact path='/get-reviewed'
          component={GetReviewed}
        />
        <PrivateRoute
          exact path='/review-a-post'
          component={ReviewAPost}
        />
        <PrivateRoute
          exact path='/status-page'
          component={Test}
        />
        <PrivateRoute
          exact path='/user-profile'
          component={UserProfile}
        />
        <PrivateRoute
          exact path='/admin'
          component={Admin}
        />
        <PrivateRoute
          path={['/test', '/status-page', '/post']}
          component={Test}
        />
        <Route
          path='/merge'
          component={() => (
            <div style={{
              height: '500px',
              width: '250px',
            }}>
              <Sharpener />
            </div>
          )}
        />
        <Route
          path='/privacy'
          component={Privacy}
        />
        <Route
          path='/not-found'
          component={NotFound}
        />
      </Switch>
    </QueryParamProvider>
  );
};
