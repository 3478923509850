import React, { FC, ReactNode } from 'react';

import '../styles/text.scss';
import '../styles/Trifold.scss';

export interface TrifoldProps {
  attentionText: string;
  button: ReactNode;
}

const Trifold: FC<TrifoldProps> = ({
  children,
  attentionText,
  button,
}) => (
  <div className='trifold basic-fold'>
    <div className='trifold__attention text__title'>
      <span>
        {attentionText}
      </span>
    </div>
    <div className='trifold__content'>
      {children}
    </div>
    {button}
  </div>
);

export default Trifold;
